import React, {
  Fragment
} from 'react';
import Maintenence from './Maintenance'


function Cls() {
  return (
    <Fragment>
      <Maintenence/>
    </Fragment>
  );
}

export default Cls;