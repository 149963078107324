import React, {Fragment} from "react";
import Video from "../../resources/Spacious.mp4";

function Maintenence() {
  return (
    <Fragment>
      <main>
        <video src={Video} autoPlay muted loop>
          Your browser is not supported!
        </video>
        <h1>Page Under Maintenence</h1>
      </main>
    </Fragment>
  );
}

export default Maintenence;
