import React from "react";
import Iframe from "react-iframe";

import ChartingImage from "../../resources/img/497400d6159e416d0b4e3942199ace78.jpg";
import HijabNurseImage from "../../resources/img/b7aa2464f2993b5705663a89f85c11f4.jpg";
import WheelChairImage from "../../resources/img/c0dd521d685e469603add71d18512521.jpg";
import RestingPatiantImage from "../../resources/img/e3ae83d3ab6ac6b264c4c37cc168b39d.jpg";
import NurseMeetingImage from "../../resources/img/1d50d89639d3e15d3b5987833898168f.jpg";
import SocialsImage from "../../resources/img/e82fe6740f52cc620ca110bf0a68f61c.jpg";
import LogoImage from "../../resources/img/dc5982e3e3b8b2cde64a075d3fdeb72f.png";
import InstagramImage from "../../resources/img/b18d32e89596ca5c80b0e70a49424b24.svg";
import AwardImage from "../../resources/img/583164be8bbd9fc75772d76a6f95e6f6.png";
import PlusImage from "../../resources/img/639860ac6bfe965ee975104682bc3794.svg";
import ButtonImage from "../../resources/img/f4b87c9caec649b370fa3657bd55a804.svg";

function Careers() {
  return (
    // <div style={{paddingTop: '8rem', height: '100vh'}}>
    //   <Iframe url="https://docs.google.com/forms/d/e/1FAIpQLScRNUEK4QH2vMGwSnbVp_dHkclGRxYHCXtMF7gUl51ydJoP2Q/viewform?embedded=true" width="100%" height="100%"  frameborder="0" marginheight="0" marginwidth="0" >Loading…</Iframe>
    // </div>
    <div id="root">
      <a id="page-1" aria-hidden="true" style={{ visibility: "hidden" }} />
      <section
        id="EHJe4a9TjZnF5oTb"
        style={{
          position: "relative",
          overflow: "hidden",
          display: "grid",
          alignItems: "center",
          gridTemplateColumns: "auto 100rem auto",
          zIndex: 0,
        }}
      >
        <div
          id="R4bFHk3K9MhsUuXQ"
          style={{
            gridArea: "1 / 1 / 2 / 4",
            display: "grid",
            position: "absolute",
            minHeight: "100%",
            minWidth: "100%",
          }}
        >
          <div id="Gcip3IHrXqoSSerB" style={{ zIndex: 0 }}>
            <div
              id="zCnvl1LnzIAfqsAZ"
              style={{
                boxSizing: "border-box",
                width: "100%",
                height: "100%",
                transform: "rotate(0deg)",
              }}
            >
              <div id="yDEHIUbR9QenBEuc" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                <div
                  id="IfLPr1GjLnrlTVo5"
                  style={{
                    backgroundColor: "#ffffff",
                    opacity: 1.0,
                    transform: "scale(1, 1)",
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                    position: "relative",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          id="yH1jqxz2dwWbkSRs"
          style={{
            display: "grid",
            position: "relative",
            gridArea: "100 / 2 / 30 / 3",
          }}
        >
          <div id="uB9gdUBrmjPAQVE6" style={{ zIndex: 5 }}>
            <div
              id="g3W21IZESFh7uEas"
              style={{ paddingTop: "125.42982512%", transform: "rotate(0deg)" }}
            >
              <div
                id="zQHoxQm3HfpjG9LM"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              >
                <div id="vydeAmdfWuUg58Yq" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                  <div
                    id="oNMPw2y1raNH6rXe"
                    style={{
                      transform: "scale(1, 1)",
                      width: "100%",
                      height: "100%",
                      overflow: "hidden",
                      position: "relative",
                    }}
                  >
                    <div
                      id="Gzpcc6sWj7QSOZgz"
                      style={{
                        width: "calc(100% * max(1, var(--scale-fill, 1)))",
                        height: "calc(107.61645166% / min(1, var(--scale-fill, 1)))",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        opacity: 1,
                        animation: "auto ease 0s 1 normal none running none",
                      }}
                    >
                      <img
                        src={LogoImage}
                        loading="lazy"
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "block",
                          objectFit: "cover",
                          objectPosition: "50% 46.46129772%",
                          transform: "translate(-50%, -46.46129772%) rotate(0deg)",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="BL7fbA4BBx5IpnqI" style={{ zIndex: 2 }}>
            <div
              id="FX2sUpNG3cn48QTY"
              style={{
                boxSizing: "border-box",
                width: "100%",
                height: "100%",
                transform: "rotate(0deg)",
              }}
            >
              <div
                id="AnOXjoZUpH4TNBbq"
                style={{
                  opacity: 1.0,
                  display: "flex",
                  boxSizing: "border-box",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  width: "100%",
                  height: "100%",
                }}
              >
                <p
                  id="xYbBrx6mafzrIqCT"
                  style={{
                    color: "#101922",
                    lineHeight: "1.11381521em",
                    textAlign: "center",
                    letterSpacing: "0.04em",
                  }}
                >
                  <span id="F7EjzOXqauhKw4nX" style={{ color: "#101922" }}>
                    Cultivating a community for caregivers to thrive &amp; discover meaningful
                    career opportunities
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div id="okdJh55tKLEwvioS" style={{ zIndex: 3 }}>
            <div
              id="DlBp9dgJPu0n2hj1"
              style={{ paddingTop: "175.32941075%", transform: "rotate(0deg)" }}
            >
              <div
                id="Jf03qSgmMz3yoaTN"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              >
                <div id="I8e4T1fjpib53rkw" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                  <div
                    id="gIZ4nGzKsEtVaT9r"
                    style={{
                      transform: "scale(1, 1)",
                      width: "100%",
                      height: "100%",
                      overflow: "hidden",
                      position: "relative",
                    }}
                  >
                    <div
                      id="MyEaKoKnrtCUwLaw"
                      style={{
                        width: "calc(262.99411613% * max(1, var(--scale-fill, 1)))",
                        height: "calc(100% / min(1, var(--scale-fill, 1)))",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        opacity: 1,
                        animation: "auto ease 0s 1 normal none running none",
                      }}
                    >
                      <img
                        src={NurseMeetingImage}
                        alt="Student nurse smiles while talking with fellow nurse"
                        loading="lazy"
                        srcSet={`${NurseMeetingImage} 1193w, ${NurseMeetingImage}`}
                        sizes="(max-width: 375px) 84.33382138vw, (min-width: 375.05px) and (max-width: 480px) 84.33382138vw, (min-width: 480.05px) and (max-width: 768px) 84.33382138vw, (min-width: 768.05px) and (max-width: 1024px) 84.33382138vw, (min-width: 1024.05px) 84.33382138vw"
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "block",
                          objectFit: "cover",
                          objectPosition: "29.01183218% 50%",
                          transform: "translate(-29.01183218%, -50%) rotate(0deg)",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="mDREwzGjCETykcMJ">
            <div
              id="aa2J8LgSNASnS01p"
              style={{
                display: "grid",
                position: "relative",
                gridArea: "3 / 8 / 9 / 9",
              }}
            >
              <div id="Hj2vCWH2yaYdooEL" style={{ zIndex: 1 }}>
                <div
                  id="wVJmo2o38vhgyzR3"
                  style={{ paddingTop: "170.66666667%", transform: "rotate(0deg)" }}
                >
                  <div
                    id="pxqvhgd7UDmxZd4D"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <svg
                      id="jlVKV8BvgY3xND33"
                      viewBox="0 0 150.7 257.1947"
                      style={{
                        width: "100%",
                        height: "100%",
                        opacity: 1.0,
                        overflow: "hidden",
                        position: "absolute",
                        top: "0%",
                        left: "0%",
                        background: 'url("data:image/png',
                      }}
                    >
                      <g id="xoUVNv7Pba5hf2xW" style={{ transform: "scale(1, 1)" }}>
                        <path
                          id="t7LaljKH9hYLfuQ4"
                          d="M0,0 L150.69999695,0 L150.69999695,257.19466361 L0,257.19466361 Z"
                          style={{ fill: "#000000", opacity: 1.0 }}
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
              <div id="hNM83Z741TiR9kHy" style={{ zIndex: 4 }}>
                <div
                  id="C4NJ1X5leFxOlzCX"
                  style={{ paddingTop: "167.93268082%", transform: "rotate(0deg)" }}
                >
                  <div
                    id="iuFtwVzl9j1lVNp6"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <div
                      id="l46PtxRliUmiDWcF"
                      style={{ width: "100%", height: "100%", opacity: 1.0 }}
                    >
                      <div
                        id="rV96HxLWpAzkmCej"
                        style={{
                          transform: "scale(1, 1)",
                          width: "100%",
                          height: "100%",
                          overflow: "hidden",
                          position: "relative",
                        }}
                      >
                        <div
                          id="nAYcGP8UAicWUgrA"
                          style={{
                            width: "calc(284.10820152% * max(1, var(--scale-fill, 1)))",
                            height: "calc(112.78654444% / min(1, var(--scale-fill, 1)))",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            opacity: 1,
                            animation: "auto ease 0s 1 normal none running none",
                          }}
                        >
                          <img
                            src={NurseMeetingImage}
                            alt="Student nurse smiles while talking with fellow nurse"
                            loading="lazy"
                            srcSet={`${NurseMeetingImage} 1193w, ${NurseMeetingImage} 1790w`}
                            sizes="(max-width: 375px) 84.33382138vw, (min-width: 375.05px) and (max-width: 480px) 84.33382138vw, (min-width: 480.05px) and (max-width: 768px) 84.33382138vw, (min-width: 768.05px) and (max-width: 1024px) 84.33382138vw, (min-width: 1024.05px) 84.33382138vw"
                            style={{
                              width: "100%",
                              height: "100%",
                              display: "block",
                              objectFit: "cover",
                              objectPosition: "67.64214233% 47.57723338%",
                              transform: "translate(-67.64214233%, -47.57723338%) rotate(0deg)",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="gj4YJ4yvRxKWtsAH" style={{ zIndex: 6 }}>
                <div
                  id="Ph3AA8zvBJsgZDq1"
                  style={{ paddingTop: "1.45148206%", transform: "rotate(0deg)" }}
                >
                  <div
                    id="es9o1BDBwRTNiW26"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <svg
                      id="il3OgcsuOZrcti0G"
                      viewBox="0 0 275.5803959935438 4.0"
                      preserveAspectRatio="none"
                      style={{
                        display: "block",
                        width: "100%",
                        height: "100%",
                        overflow: "visible",
                        opacity: 1.0,
                        minHeight: 1,
                        stroke: "#bd1723",
                        fill: "#bd1723",
                        background: 'url("data:image/png',
                      }}
                    >
                      <g id="l6I94BfI8iFjcpuU">
                        <path
                          d="M0,2 L275.58039599,2"
                          style={{
                            fill: "none",
                            strokeWidth: 4,
                            strokeLinecap: "butt",
                          }}
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
              <div id="gdFbF9eUNpIBUzs1" style={{ zIndex: 7 }}>
                <div
                  id="K9A4W6weeUOBeKuN"
                  style={{ paddingTop: "1.56108307%", transform: "rotate(0deg)" }}
                >
                  <div
                    id="A3FC27mi6NcSfFhM"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <svg
                      id="nzr3LFNSSfQ7O6hU"
                      viewBox="0 0 385.272886537055 6.014429805185273"
                      preserveAspectRatio="none"
                      style={{
                        display: "block",
                        width: "100%",
                        height: "100%",
                        overflow: "visible",
                        opacity: 1.0,
                        minHeight: 1,
                        stroke: "#bd1723",
                        fill: "#bd1723",
                        background: 'url("data:image/png',
                      }}
                    >
                      <g id="FuTQxJBAGU0K7dAl">
                        <path
                          d="M0.01045787,1.99997266 L385.26242867,4.01445715"
                          style={{
                            fill: "none",
                            strokeWidth: 4,
                            strokeLinecap: "butt",
                          }}
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <a id="about-us" aria-hidden="true" style={{ visibility: "hidden" }} />
      <section
        id="qxi1dJYpuEgQ3GAP"
        style={{
          position: "relative",
          overflow: "hidden",
          display: "grid",
          alignItems: "center",
          gridTemplateColumns: "auto 100rem auto",
          zIndex: 0,
          marginTop: "-1px",
        }}
      >
        <div
          id="hd9DCGsDJbnCUTMH"
          style={{
            gridArea: "1 / 1 / 2 / 4",
            display: "grid",
            position: "absolute",
            minHeight: "100%",
            minWidth: "100%",
          }}
        >
          <div id="ZvAIGWL199mtdQty" style={{ zIndex: 0 }}>
            <div
              id="pUzIn83sM4hcNxWW"
              style={{
                boxSizing: "border-box",
                width: "100%",
                height: "100%",
                transform: "rotate(0deg)",
              }}
            >
              <div id="toCxJ8hpL7CtMWkg" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                <div
                  id="rznJkqxREfZj9TqJ"
                  style={{
                    backgroundColor: "#000000",
                    opacity: 1.0,
                    transform: "scale(1, 1)",
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                    position: "relative",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          id="M26r7EuNKRfQjP1f"
          style={{
            display: "grid",
            position: "relative",
            gridArea: "1 / 2 / 2 / 3",
          }}
        >
          <div id="r90enHsYajhqod6n" style={{ zIndex: 1, pointerEvents: "none" }}>
            <div
              id="NDPGsVcVomdqV1BM"
              style={{ boxSizing: "border-box", width: "100%", height: "100%" }}
            >
              <div className="animation_container" style={{ width: "100%", height: "100%" }}>
                <div
                  className="animated"
                  style={{
                    width: "100%",
                    height: "100%",
                    animation:
                      "5000ms ease 100ms 1 normal both running breathe-LEFT-5cbd11b9-4860-4337-82ba-f5b630d0afb6",
                  }}
                >
                  <div
                    id="AB5tuhlSHL4A61dS"
                    style={{
                      opacity: 1.0,
                      display: "flex",
                      boxSizing: "border-box",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <p
                      id="p2klDFBhgs2IIzu0"
                      style={{
                        color: "#ffffff",
                        lineHeight: "1.55489132em",
                        textAlign: "center",
                      }}
                    >
                      <span id="wsGp773pN4VaGVha" style={{ color: "#ffffff" }}>
                        Welcome to MYKA PCH, a premier provider of skilled nursing, and personal
                        care services for individuals with a wide range of needs. We are currently
                        seeking LPN’s and RN’s to join our team and provide exceptional care to our
                        patients.
                      </span>
                    </p>
                    <p
                      id="L6b9uLo7BkyIwbjg"
                      style={{
                        color: "#ffffff",
                        direction: "ltr",
                        marginLeft: "0em",
                        lineHeight: "1.55357282em",
                        textAlign: "center",
                        textTransform: "none",
                        letterSpacing: "0em",
                        whiteSpace: "pre",
                      }}
                    >
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="EUGlla4aWTH7F2vY">
            <div
              id="oRCzniDlPNnYzH32"
              style={{
                display: "grid",
                position: "relative",
                gridArea: "3 / 3 / 5 / 4",
              }}
            >
              <div id="EBbqjM05jht5l4qT" style={{ zIndex: 2 }}>
                <div id="UDDcCgRSMzI44RFi" style={{ paddingTop: "28.125%" }}>
                  <div
                    id="VG364Ek1JyHrUFPg"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <div className="animation_container" style={{ width: "100%", height: "100%" }}>
                      <div
                        className="animated"
                        style={{
                          width: "100%",
                          height: "100%",
                          animation:
                            "5000ms ease 100ms 1 normal both running breathe-LEFT-a82bd025-73a6-4cda-8add-acba4c87c80a",
                        }}
                      >
                        <a
                          id="B3vwZgWxJhoV5f5q"
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://forms.gle/NkWLCqjFwbtjLTdm9"
                          style={{ pointerEvents: "all" }}
                          data-interstitial-link=""
                        >
                          <div
                            id="Nv15d34RNVCyhXCv"
                            style={{ width: "100%", height: "100%", opacity: 1.0 }}
                          >
                            <div
                              id="RqNup3AS52iSt0Lz"
                              style={{
                                transform: "scale(1, 1)",
                                width: "100%",
                                height: "100%",
                                overflow: "hidden",
                                position: "relative",
                              }}
                            >
                              <div
                                id="TJqlVZpLtYLfBaOU"
                                style={{
                                  width: "calc(100% * max(1, var(--scale-fill, 1)))",
                                  height: "calc(100% / min(1, var(--scale-fill, 1)))",
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  opacity: 1,
                                  animation: "auto ease 0s 1 normal none running none",
                                }}
                              >
                                <img
                                  src={ButtonImage}
                                  alt="Button"
                                  loading="lazy"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    display: "block",
                                    objectFit: "cover",
                                    objectPosition: "50% 50%",
                                    transform: "translate(-50%, -50%) rotate(0deg)",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="AkuMzJ6ee2hQPfpP" style={{ zIndex: 3, pointerEvents: "none" }}>
                <div
                  id="ULKpIAXmD5Yo6ZUm"
                  style={{ boxSizing: "border-box", width: "100%", height: "100%" }}
                >
                  <div className="animation_container" style={{ width: "100%", height: "100%" }}>
                    <div
                      className="animated"
                      style={{
                        width: "100%",
                        height: "100%",
                        animation:
                          "5000ms ease 100ms 1 normal both running breathe-RIGHT-955f41a9-f0ee-42e0-9f9e-c0ab54f90d7f",
                      }}
                    >
                      <div
                        id="KA1ruxmY3fDQ0CHz"
                        style={{
                          opacity: 1.0,
                          display: "flex",
                          boxSizing: "border-box",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <p
                          id="Zh5lAIkgkw3mQD23"
                          style={{
                            color: "#ffffff",
                            lineHeight: "1.3741434em",
                            textAlign: "center",
                          }}
                        >
                          <span id="LsX8gqKZFylek5FZ" style={{ color: "#ffffff", fontWeight: 700 }}>
                            APPLY NOW!
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <a id="page-3" aria-hidden="true" style={{ visibility: "hidden" }} />
      <section
        id="Cy1PaoWfviQxRs5a"
        style={{
          position: "relative",
          overflow: "hidden",
          display: "grid",
          alignItems: "center",
          gridTemplateColumns: "auto 100rem auto",
          zIndex: 0,
          marginTop: "-1px",
        }}
      >
        <div
          id="hgmCugqHt6bM7JSf"
          style={{
            gridArea: "1 / 1 / 2 / 4",
            display: "grid",
            position: "absolute",
            minHeight: "100%",
            minWidth: "100%",
          }}
        >
          <div id="FFamOyY9L43hFdCg" style={{ zIndex: 0 }}>
            <div
              id="Kd11LaZh9R4EnVeP"
              style={{
                boxSizing: "border-box",
                width: "100%",
                height: "100%",
                transform: "rotate(0deg)",
              }}
            >
              <div id="VYmSpuyfa6CcQ6Ba" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                <div
                  id="TUPUFnbunJ2BPpYF"
                  style={{
                    backgroundColor: "#bd1723",
                    opacity: 1.0,
                    transform: "scale(1, 1)",
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                    position: "relative",
                  }}
                >
                  <div
                    id="Vw2e1qdUpGVpSckw"
                    style={{
                      width: "calc(100.09785808% * max(1, var(--scale-fill, 1)))",
                      height: "calc(118.69242547% / min(1, var(--scale-fill, 1)))",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      opacity: "0.5",
                      animation: "auto ease 0s 1 normal none running none",
                    }}
                  >
                    <img
                      src={HijabNurseImage}
                      alt="Confident female nurse."
                      loading="lazy"
                      srcSet={`${HijabNurseImage} 1732w, ${HijabNurseImage} 2598w`}
                      sizes="(max-width: 375px) 100.09785808vw, (min-width: 375.05px) and (max-width: 480px) 100.09785808vw, (min-width: 480.05px) and (max-width: 768px) 100.09785808vw, (min-width: 768.05px) and (max-width: 1024px) 100.09785808vw, (min-width: 1024.05px) 100.09785808vw"
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "block",
                        objectFit: "cover",
                        objectPosition: "50.04888121% 42.1256873%",
                        transform: "translate(-50.04888121%, -42.1256873%) rotate(0deg)",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="OvvMkFI95PY5lI31"
          style={{
            display: "grid",
            position: "relative",
            gridArea: "1 / 2 / 2 / 3",
          }}
        >
          <div id="NwkRNePE1Q9SWrKB" style={{ zIndex: 1 }}>
            <div
              id="WemUxubcgv2ggcRw"
              style={{
                boxSizing: "border-box",
                width: "100%",
                height: "100%",
                transform: "rotate(0deg)",
              }}
            >
              <div
                id="td6EGz77roQlxnv3"
                style={{
                  opacity: 1.0,
                  display: "flex",
                  boxSizing: "border-box",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  width: "100%",
                  height: "100%",
                }}
              >
                <p
                  id="sbid0hVA5U1i60dK"
                  style={{
                    color: "#ffffff",
                    lineHeight: "0.98438346em",
                    textTransform: "uppercase",
                    letterSpacing: "0em",
                  }}
                >
                  <span id="FHUsYeUiXkAlxBwp" style={{ color: "#ffffff", fontWeight: 700 }}>
                    living wages that compliment your lifestyle.{" "}
                  </span>
                  <br />
                </p>
              </div>
            </div>
          </div>
          <div id="ZRGfJC8CVYOn4ICt" style={{ zIndex: 2 }}>
            <div
              id="nYolEmHEUkq6c9dk"
              style={{
                boxSizing: "border-box",
                width: "100%",
                height: "100%",
                transform: "rotate(0deg)",
              }}
            >
              <div
                id="ic2Hde6op0tE3pOV"
                style={{
                  opacity: 1.0,
                  display: "flex",
                  boxSizing: "border-box",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  width: "100%",
                  height: "100%",
                }}
              >
                <ul id="xbXNO3vgEx3OVWZs">
                  <li
                    id="Ty23ULE0tB2Nvpea"
                    style={{
                      color: "#ffffff",
                      marginLeft: "1.7em",
                      lineHeight: "1.66304709em",
                      listStyleType: "disc",
                      letterSpacing: "0em",
                    }}
                  >
                    <span id="yglGbJc8XQQxqrir" style={{ color: "#ffffff", fontWeight: 700 }}>
                      {" "}
                      Earn $27- $30/hr
                    </span>
                    <span id="fDe6hmth20XC5AjI" style={{ color: "#ffffff", fontWeight: 700 }}>
                      {" "}
                    </span>
                  </li>
                  <li
                    id="hkg6wjagLVyf04N9"
                    style={{
                      color: "#ffffff",
                      marginLeft: "1.7em",
                      lineHeight: "1.66304709em",
                      listStyleType: "disc",
                      letterSpacing: "0em",
                    }}
                  >
                    <span id="nvYi5YGOSmKd1eCP" style={{ color: "#ffffff", fontWeight: 700 }}>
                      Weekly Pay
                    </span>
                  </li>
                  <li
                    id="vqV4OOlDqnBupciK"
                    style={{
                      color: "#ffffff",
                      marginLeft: "1.7em",
                      lineHeight: "1.66304709em",
                      listStyleType: "disc",
                      letterSpacing: "0em",
                    }}
                  >
                    <span id="DOSsRaCqiPYbz7Lz" style={{ color: "#ffffff", fontWeight: 700 }}>
                      Flexible Scheduling For Your Convenience{" "}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div id="JE09gLAD0lOkMTkB">
            <div
              id="ijhRrpO0ZVZxzsH2"
              style={{
                display: "grid",
                position: "relative",
                gridArea: "6 / 4 / 7 / 5",
              }}
            >
              <div id="Pg1H9KHcUrYWirzx" style={{ zIndex: 3 }}>
                <div
                  id="yZJtWF9f7wsKLZ99"
                  style={{ paddingTop: "28.125%", transform: "rotate(0deg)" }}
                >
                  <div
                    id="jcDMXmO1s38SVKu5"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <a
                      id="aZDBd3jp4B9C5G6t"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://forms.gle/NkWLCqjFwbtjLTdm9"
                      style={{ pointerEvents: "all" }}
                      data-interstitial-link=""
                    >
                      <div
                        id="HF4hZbNnmqqYN9Dr"
                        style={{ width: "100%", height: "100%", opacity: 1.0 }}
                      >
                        <div
                          id="dnUbwWDj35ZNjpoS"
                          style={{
                            transform: "scale(1, 1)",
                            width: "100%",
                            height: "100%",
                            overflow: "hidden",
                            position: "relative",
                          }}
                        >
                          <div
                            id="QsgkfFzT6EYqMyMe"
                            style={{
                              width: "calc(100% * max(1, var(--scale-fill, 1)))",
                              height: "calc(100% / min(1, var(--scale-fill, 1)))",
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              opacity: 1,
                              animation: "auto ease 0s 1 normal none running none",
                            }}
                          >
                            <img
                              src={ButtonImage}
                              alt="Button"
                              loading="lazy"
                              style={{
                                width: "100%",
                                height: "100%",
                                display: "block",
                                objectFit: "cover",
                                objectPosition: "50% 50%",
                                transform: "translate(-50%, -50%) rotate(0deg)",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div id="KxUBSgbSii92WswK" style={{ zIndex: 4, pointerEvents: "none" }}>
                <div
                  id="MUvZvHoZnbpQy2Fg"
                  style={{
                    boxSizing: "border-box",
                    width: "100%",
                    height: "100%",
                    transform: "rotate(0deg)",
                  }}
                >
                  <div
                    id="NhjFutAzLTBEK3N4"
                    style={{
                      opacity: 1.0,
                      display: "flex",
                      boxSizing: "border-box",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <p
                      id="u97ggHtTl5CjCFr2"
                      style={{
                        color: "#ffffff",
                        lineHeight: "1.39779392em",
                        textAlign: "center",
                      }}
                    >
                      <span id="m5r5PFBipEP6Np9O" style={{ color: "#ffffff", fontWeight: 700 }}>
                        APPLY NOW!
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <a id="page-4" aria-hidden="true" style={{ visibility: "hidden" }} />
      <section
        id="IGQgWz9buyfmxwOX"
        style={{
          position: "relative",
          overflow: "hidden",
          display: "grid",
          alignItems: "center",
          gridTemplateColumns: "auto 100rem auto",
          zIndex: 0,
          marginTop: "-1px",
        }}
      >
        <div
          id="y1Vye7h8aQaaCSRZ"
          style={{
            gridArea: "1 / 1 / 2 / 4",
            display: "grid",
            position: "absolute",
            minHeight: "100%",
            minWidth: "100%",
          }}
        >
          <div id="RmijFNceVqO7Fs3J" style={{ zIndex: 0 }}>
            <div
              id="HksFtWgN1d6CrQTr"
              style={{
                boxSizing: "border-box",
                width: "100%",
                height: "100%",
                transform: "rotate(0deg)",
              }}
            >
              <div id="cxvr9XJAYmaLfPqf" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                <div
                  id="w7cvujKJzHDh4xH4"
                  style={{
                    backgroundColor: "#ffffff",
                    opacity: 1.0,
                    transform: "scale(1, 1)",
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                    position: "relative",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          id="rF78NrtcKVQUrqZt"
          style={{
            display: "grid",
            position: "relative",
            gridArea: "1 / 2 / 2 / 3",
          }}
        >
          <div id="dsGaowxU9EVzl1tT">
            <div
              id="Iikie6NHQzNDHWuB"
              style={{
                display: "grid",
                position: "relative",
                gridArea: "3 / 2 / 4 / 18",
              }}
            >
              <div id="QRqrShqvd6dgCaht" style={{ zIndex: 10 }}>
                <div
                  id="mg6YZjNYcDGsDEM9"
                  style={{ boxSizing: "border-box", width: "100%", height: "100%" }}
                >
                  <div className="animation_container" style={{ width: "100%", height: "100%" }}>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        transform: "rotate(0deg)",
                        overflow: "hidden",
                      }}
                    >
                      <div
                        className="animated"
                        style={{
                          width: "100%",
                          height: "100%",
                          animation:
                            "baseline-LEFT-87e72ba0-8012-4cac-9b54-d6927be107ac 500ms 100ms both paused",
                        }}
                      >
                        <div
                          id="jzrAYhXhr34yZVBt"
                          style={{
                            opacity: 1.0,
                            display: "flex",
                            boxSizing: "border-box",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <p
                            id="EsHH1pIVIoFxMfcb"
                            style={{
                              color: "#221f1f",
                              direction: "ltr",
                              marginLeft: "0em",
                              lineHeight: "1.00289546em",
                              textTransform: "none",
                              letterSpacing: "0em",
                            }}
                          >
                            <span
                              id="k0Ci3UQYzaxRayI4"
                              style={{
                                textDecorationLine: "none",
                                color: "#221f1f",
                              }}
                            >
                              Our Services
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="jXpmqkj2JmUatY0n" style={{ zIndex: 11 }}>
                <div
                  id="u9MNcVEjAOZ3rGlU"
                  style={{ boxSizing: "border-box", width: "100%", height: "100%" }}
                >
                  <div className="animation_container" style={{ width: "100%", height: "100%" }}>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        transform: "rotate(0deg)",
                        overflow: "hidden",
                      }}
                    >
                      <div
                        className="animated"
                        style={{
                          width: "100%",
                          height: "100%",
                          animation:
                            "baseline-LEFT-010502a0-e8f8-4923-a286-eceae063bcfa 500ms 100ms both paused",
                        }}
                      >
                        <div
                          id="RIKncFyr9pIUSUie"
                          style={{
                            opacity: 1.0,
                            display: "flex",
                            boxSizing: "border-box",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <p
                            id="JIuf7v0lKOXoVVAx"
                            style={{
                              color: "#221f1f",
                              direction: "ltr",
                              marginLeft: "0em",
                              lineHeight: "1.38862795em",
                              textTransform: "none",
                              letterSpacing: "0em",
                            }}
                          >
                            <span
                              id="mtNb8SzQStcSrnu8"
                              style={{ color: "#221f1f", fontWeight: 700 }}
                            >
                              Here at MYKA PCH, we offer a variety of specialized services
                              including:
                            </span>
                            <span
                              id="YKdhEBiSHWNou8b4"
                              style={{
                                textDecorationLine: "none",
                                color: "#221f1f",
                                fontWeight: 700,
                              }}
                            >
                              {" "}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="c13F6904MVKNpyVT" style={{ zIndex: 12 }}>
            <div
              id="WscXJmmrtcwvsJRP"
              style={{ boxSizing: "border-box", width: "100%", height: "100%" }}
            >
              <div className="animation_container" style={{ width: "100%", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    transform: "rotate(0deg)",
                    overflow: "hidden",
                  }}
                >
                  <div
                    className="animated"
                    style={{
                      width: "100%",
                      height: "100%",
                      animation:
                        "baseline-LEFT-58aa9047-4c2e-4fdb-9433-87e4f9d4d111 500ms 100ms both paused",
                    }}
                  >
                    <div
                      id="gNgIpczgkfSqPrT7"
                      style={{
                        opacity: 1.0,
                        display: "flex",
                        boxSizing: "border-box",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <p
                        id="MIRJ2qV9jGAzI4nY"
                        style={{
                          color: "#343434",
                          lineHeight: "0.99999583em",
                        }}
                      >
                        <span id="bQlSniZRy5wnloNz" style={{ color: "#343434", fontWeight: 700 }}>
                          ICWP
                        </span>
                        <br />
                      </p>
                      <p
                        id="HbBuYw0oCiveIm75"
                        style={{
                          color: "#343434",
                          direction: "ltr",
                          marginLeft: "0em",
                          lineHeight: "0.99999583em",
                          letterSpacing: "0em",
                        }}
                      >
                        <span id="mhEIqYlbh9FNrQve" style={{ color: "#343434" }}>
                          Traumatic Brain Injuries
                        </span>
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="U85i265wNQbw8T6A" style={{ zIndex: 2 }}>
            <div id="iBzRzek4gnh2fQy9" style={{ paddingTop: "100%" }}>
              <div
                id="bu4x6m0rt98t0jiW"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              >
                <div className="animation_container" style={{ width: "100%", height: "100%" }}>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      transform: "rotate(0deg)",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      className="animated"
                      style={{
                        width: "100%",
                        height: "100%",
                        animation:
                          "baseline-RIGHT-6bfde62d-b4cc-4ee9-869a-81d9567a81d1 500ms 100ms both paused",
                      }}
                    >
                      <div
                        id="JwUoV3mD4y1iql3d"
                        style={{ width: "100%", height: "100%", opacity: 1.0 }}
                      >
                        <div
                          id="pGVA8eu9JB2n3fAy"
                          style={{
                            transform: "scale(1, 1)",
                            width: "100%",
                            height: "100%",
                            overflow: "hidden",
                            position: "relative",
                          }}
                        >
                          <div
                            id="DIBWkLqrTPM5mhQn"
                            style={{
                              width: "calc(100% * max(1, var(--scale-fill, 1)))",
                              height: "calc(100% / min(1, var(--scale-fill, 1)))",
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              opacity: 1,
                              animation: "auto ease 0s 1 normal none running none",
                            }}
                          >
                            <img
                              src={PlusImage}
                              alt="heavy plus sign"
                              loading="lazy"
                              style={{
                                width: "100%",
                                height: "100%",
                                display: "block",
                                objectFit: "cover",
                                objectPosition: "50% 50%",
                                transform: "translate(-50%, -50%) rotate(0deg)",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="fGXtQn2TxF0u8sQK" style={{ zIndex: 1 }}>
            <div id="a4IMcrZis1nFvyS3" style={{ paddingTop: "0.5324864%" }}>
              <div
                id="RzTnmuA2tye4R3nO"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              >
                <div className="animation_container" style={{ width: "100%", height: "100%" }}>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      transform: "rotate(0deg)",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      className="animated"
                      style={{
                        width: "100%",
                        height: "100%",
                        animation:
                          "baseline-LEFT-3f55d95d-974f-4dcc-96ae-97d42f86beb6 500ms 100ms both paused",
                      }}
                    >
                      <div
                        id="UnLPHa0KG2wKxY2k"
                        style={{ width: "100%", height: "100%", opacity: 1.0 }}
                      >
                        <div
                          id="MdVFqWfTu018l4KS"
                          style={{
                            backgroundColor: "#bd1723",
                            opacity: 1.0,
                            transform: "scale(1, 1)",
                            width: "100%",
                            height: "100%",
                            overflow: "hidden",
                            position: "relative",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="qtZ1Kp35L1YFOMrC" style={{ zIndex: 13 }}>
            <div
              id="QhGfiNC4tKlmqZXA"
              style={{ boxSizing: "border-box", width: "100%", height: "100%" }}
            >
              <div className="animation_container" style={{ width: "100%", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    transform: "rotate(0deg)",
                    overflow: "hidden",
                  }}
                >
                  <div
                    className="animated"
                    style={{
                      width: "100%",
                      height: "100%",
                      animation:
                        "baseline-LEFT-6aa5f1de-ec89-4a5f-b61a-9cd056f45358 500ms 100ms both paused",
                    }}
                  >
                    <div
                      id="LjHLSRvoqG4r58Lk"
                      style={{
                        opacity: 1.0,
                        display: "flex",
                        boxSizing: "border-box",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <p
                        id="iBI7gsSYLx6PsCWJ"
                        style={{
                          color: "#343434",
                          lineHeight: "0.99999583em",
                        }}
                      >
                        <span id="rmK48oJZEVk7BVmg" style={{ color: "#343434", fontWeight: 700 }}>
                          GAPP
                        </span>
                        <br />
                      </p>
                      <p
                        id="TQqu6u0aLQxKLqIb"
                        style={{
                          color: "#343434",
                          direction: "ltr",
                          marginLeft: "0em",
                          lineHeight: "0.99999583em",
                          letterSpacing: "0em",
                        }}
                      >
                        <span id="NT3gmnYUAa1rzfxP" style={{ color: "#343434" }}>
                          Pediatric Care
                        </span>
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="onNVpINpFa5zgEgR" style={{ zIndex: 3 }}>
            <div id="p2g34E8RAxdEC0XT" style={{ paddingTop: "100%" }}>
              <div
                id="FLJzD00sjuuGU3Kj"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              >
                <div className="animation_container" style={{ width: "100%", height: "100%" }}>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      transform: "rotate(0deg)",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      className="animated"
                      style={{
                        width: "100%",
                        height: "100%",
                        animation:
                          "baseline-RIGHT-2f5e432d-cfa7-4b6f-b507-7dee9485fd0b 500ms 100ms both paused",
                      }}
                    >
                      <div
                        id="pFtuFPTy0NeXzCD4"
                        style={{ width: "100%", height: "100%", opacity: 1.0 }}
                      >
                        <div
                          id="fiVEBRPOw8LeraOT"
                          style={{
                            transform: "scale(1, 1)",
                            width: "100%",
                            height: "100%",
                            overflow: "hidden",
                            position: "relative",
                          }}
                        >
                          <div
                            id="ag4wwcPMUWHGDQsW"
                            style={{
                              width: "calc(100% * max(1, var(--scale-fill, 1)))",
                              height: "calc(100% / min(1, var(--scale-fill, 1)))",
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              opacity: 1,
                              animation: "auto ease 0s 1 normal none running none",
                            }}
                          >
                            <img
                              src={PlusImage}
                              alt="heavy plus sign"
                              loading="lazy"
                              style={{
                                width: "100%",
                                height: "100%",
                                display: "block",
                                objectFit: "cover",
                                objectPosition: "50% 50%",
                                transform: "translate(-50%, -50%) rotate(0deg)",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="WOFSN6jl6Ycg0BXK" style={{ zIndex: 5 }}>
            <div id="eAGK2jiTbGSScOl4" style={{ paddingTop: "0.5324864%" }}>
              <div
                id="A11NE27IR807Lk1G"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              >
                <div className="animation_container" style={{ width: "100%", height: "100%" }}>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      transform: "rotate(0deg)",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      className="animated"
                      style={{
                        width: "100%",
                        height: "100%",
                        animation:
                          "baseline-LEFT-b310b178-0e26-426e-90e8-d38fc1a380cd 500ms 100ms both paused",
                      }}
                    >
                      <div
                        id="TEia8LTp3fix01K4"
                        style={{ width: "100%", height: "100%", opacity: 1.0 }}
                      >
                        <div
                          id="qMlyXtC5OONzzjuD"
                          style={{
                            backgroundColor: "#bd1723",
                            opacity: 1.0,
                            transform: "scale(1, 1)",
                            width: "100%",
                            height: "100%",
                            overflow: "hidden",
                            position: "relative",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="sfuRSbOq2STMCRnA" style={{ zIndex: 14 }}>
            <div
              id="L89XGpgiQRagWZxL"
              style={{ boxSizing: "border-box", width: "100%", height: "100%" }}
            >
              <div className="animation_container" style={{ width: "100%", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    transform: "rotate(0deg)",
                    overflow: "hidden",
                  }}
                >
                  <div
                    className="animated"
                    style={{
                      width: "100%",
                      height: "100%",
                      animation:
                        "baseline-LEFT-910ba1a2-4ed3-4a0b-9bec-ad3097f42ff2 500ms 100ms both paused",
                    }}
                  >
                    <div
                      id="m4k18ITxxsL9QFGM"
                      style={{
                        opacity: 1.0,
                        display: "flex",
                        boxSizing: "border-box",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <p
                        id="WwdPYj5hi5OJEaQS"
                        style={{
                          color: "#343434",
                          lineHeight: "0.99999583em",
                        }}
                      >
                        <span id="KUtuBzsSnih5AICU" style={{ color: "#343434", fontWeight: 700 }}>
                          DBHDD
                        </span>
                        <br />
                      </p>
                      <p
                        id="CMjC5N8UaaUdalAx"
                        style={{
                          color: "#343434",
                          direction: "ltr",
                          marginLeft: "0em",
                          lineHeight: "0.99999583em",
                          letterSpacing: "0em",
                        }}
                      >
                        <span id="HU1JV3ncntXamvsq" style={{ color: "#343434" }}>
                          Intellectual Developmental Disability
                        </span>
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="uWlILl6qsIML3Abq" style={{ zIndex: 4 }}>
            <div id="JOLb7IUFUubvhcxd" style={{ paddingTop: "100%" }}>
              <div
                id="NhgL9OL7YAxYhTCG"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              >
                <div className="animation_container" style={{ width: "100%", height: "100%" }}>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      transform: "rotate(0deg)",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      className="animated"
                      style={{
                        width: "100%",
                        height: "100%",
                        animation:
                          "baseline-RIGHT-6dd4dd69-3f49-4fc1-ac11-96c7a32b61f6 500ms 100ms both paused",
                      }}
                    >
                      <div
                        id="TgWaHpEXlgY0OtBt"
                        style={{ width: "100%", height: "100%", opacity: 1.0 }}
                      >
                        <div
                          id="Br6pwPiiN6dmuIY8"
                          style={{
                            transform: "scale(1, 1)",
                            width: "100%",
                            height: "100%",
                            overflow: "hidden",
                            position: "relative",
                          }}
                        >
                          <div
                            id="pBajzz1rRtMA2HJ9"
                            style={{
                              width: "calc(100% * max(1, var(--scale-fill, 1)))",
                              height: "calc(100% / min(1, var(--scale-fill, 1)))",
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              opacity: 1,
                              animation: "auto ease 0s 1 normal none running none",
                            }}
                          >
                            <img
                              src={PlusImage}
                              alt="heavy plus sign"
                              loading="lazy"
                              style={{
                                width: "100%",
                                height: "100%",
                                display: "block",
                                objectFit: "cover",
                                objectPosition: "50% 50%",
                                transform: "translate(-50%, -50%) rotate(0deg)",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="GxLZcKsgC2Hilfjd" style={{ zIndex: 6 }}>
            <div id="eJBRfbxZV0mVlUp1" style={{ paddingTop: "0.5324864%" }}>
              <div
                id="PkPCotmBnm3uV2SP"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              >
                <div className="animation_container" style={{ width: "100%", height: "100%" }}>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      transform: "rotate(0deg)",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      className="animated"
                      style={{
                        width: "100%",
                        height: "100%",
                        animation:
                          "baseline-LEFT-da2cb3a1-f881-4cf3-b922-a2dbda41c2ac 500ms 100ms both paused",
                      }}
                    >
                      <div
                        id="WAzi8jvbzJ355RGI"
                        style={{ width: "100%", height: "100%", opacity: 1.0 }}
                      >
                        <div
                          id="mdvNDuIRzMPK6q7s"
                          style={{
                            backgroundColor: "#bd1723",
                            opacity: 1.0,
                            transform: "scale(1, 1)",
                            width: "100%",
                            height: "100%",
                            overflow: "hidden",
                            position: "relative",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="gRtoAZXOZ73ARV3Y" style={{ zIndex: 15 }}>
            <div
              id="mUHVS577UYfY5gke"
              style={{ boxSizing: "border-box", width: "100%", height: "100%" }}
            >
              <div className="animation_container" style={{ width: "100%", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    transform: "rotate(0deg)",
                    overflow: "hidden",
                  }}
                >
                  <div
                    className="animated"
                    style={{
                      width: "100%",
                      height: "100%",
                      animation:
                        "baseline-LEFT-091a4b84-fbb4-4870-a7df-a8d935cd001f 500ms 100ms both paused",
                    }}
                  >
                    <div
                      id="vpnVdpcR5ZSWo8T3"
                      style={{
                        opacity: 1.0,
                        display: "flex",
                        boxSizing: "border-box",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <p
                        id="aQWCknqjztYHpTod"
                        style={{
                          color: "#343434",
                          lineHeight: "0.99999583em",
                        }}
                      >
                        <span id="Afc4NE5AMw6JX1Wm" style={{ color: "#343434", fontWeight: 700 }}>
                          EDWP
                        </span>
                      </p>
                      <p
                        id="XXC7EZjp69bNCAIa"
                        style={{
                          color: "#343434",
                          direction: "ltr",
                          marginLeft: "0em",
                          lineHeight: "0.99999583em",
                          letterSpacing: "0em",
                        }}
                      >
                        <span id="IU2d23UUajMNxGuY" style={{ color: "#343434" }}>
                          Elderly Waiver
                        </span>
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="SvMKEAw6mQYt3EGi" style={{ zIndex: 7 }}>
            <div id="XfjKU1RFUeNyO56I" style={{ paddingTop: "100%" }}>
              <div
                id="mkRuYwZ8w1lh3WIU"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              >
                <div className="animation_container" style={{ width: "100%", height: "100%" }}>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      transform: "rotate(0deg)",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      className="animated"
                      style={{
                        width: "100%",
                        height: "100%",
                        animation:
                          "baseline-RIGHT-2e4050f9-3efd-4326-a2a7-79185a712fb8 500ms 100ms both paused",
                      }}
                    >
                      <div
                        id="HS7iYAbSJhNkQz66"
                        style={{ width: "100%", height: "100%", opacity: 1.0 }}
                      >
                        <div
                          id="fhI3b8cwfkTFJZ8N"
                          style={{
                            transform: "scale(1, 1)",
                            width: "100%",
                            height: "100%",
                            overflow: "hidden",
                            position: "relative",
                          }}
                        >
                          <div
                            id="uRwk4vfClyUjiGqX"
                            style={{
                              width: "calc(100% * max(1, var(--scale-fill, 1)))",
                              height: "calc(100% / min(1, var(--scale-fill, 1)))",
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              opacity: 1,
                              animation: "auto ease 0s 1 normal none running none",
                            }}
                          >
                            <img
                              src={PlusImage}
                              alt="heavy plus sign"
                              loading="lazy"
                              style={{
                                width: "100%",
                                height: "100%",
                                display: "block",
                                objectFit: "cover",
                                objectPosition: "50% 50%",
                                transform: "translate(-50%, -50%) rotate(0deg)",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="VptxBkH6YTytsvBQ" style={{ zIndex: 8 }}>
            <div id="GEhxzk51rd9J3jk3" style={{ paddingTop: "129.32637999%" }}>
              <div
                id="tkJqJy2187NS5FLi"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              >
                <div className="animation_container" style={{ width: "100%", height: "100%" }}>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      transform: "rotate(0deg)",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      className="animated"
                      style={{
                        width: "100%",
                        height: "100%",
                        animation:
                          "baseline-RIGHT-508549ff-23f6-4cf6-8b36-2c403c232e24 500ms 100ms both paused",
                      }}
                    >
                      <div
                        id="V4WMyaiFwmQezffm"
                        style={{ width: "100%", height: "100%", opacity: 1.0 }}
                      >
                        <div
                          id="cSR7OuuT7AcdJ0jX"
                          style={{
                            transform: "scale(1, 1)",
                            width: "100%",
                            height: "100%",
                            overflow: "hidden",
                            position: "relative",
                          }}
                        >
                          <div
                            id="rR0qgAr34R8eMs5J"
                            style={{
                              width: "calc(144.09624511% * max(1, var(--scale-fill, 1)))",
                              height: "calc(100% / min(1, var(--scale-fill, 1)))",
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              opacity: 1,
                              animation: "auto ease 0s 1 normal none running none",
                            }}
                          >
                            <img
                              src={ChartingImage}
                              alt="Nurse"
                              loading="lazy"
                              srcSet={`${ChartingImage} 514w, ${ChartingImage} 1028w`}
                              sizes="(max-width: 375px) 50.11480565vw, (min-width: 375.05px) and (max-width: 480px) 50.11480565vw, (min-width: 480.05px) and (max-width: 768px) 50.11480565vw, (min-width: 768.05px) and (max-width: 1024px) 50.11480565vw, (min-width: 1024.05px) 50.11480565vw"
                              style={{
                                width: "100%",
                                height: "100%",
                                display: "block",
                                objectFit: "cover",
                                objectPosition: "45.81087832% 50%",
                                transform: "translate(-45.81087832%, -50%) rotate(0deg)",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <a id="testimonials" aria-hidden="true" style={{ visibility: "hidden" }} />
      <section
        id="zwKgah8dqbXVOUpH"
        style={{
          position: "relative",
          overflow: "hidden",
          display: "grid",
          alignItems: "center",
          gridTemplateColumns: "auto 100rem auto",
          zIndex: 0,
          marginTop: "-1px",
        }}
      >
        <div
          id="moYcaMc9ZCjaQ5Kk"
          style={{
            gridArea: "1 / 1 / 2 / 4",
            display: "grid",
            position: "absolute",
            minHeight: "100%",
            minWidth: "100%",
          }}
        >
          <div id="RBLku5bRpfbZzoL5" style={{ zIndex: 0 }}>
            <div
              id="ZplOnWqJagxL09Ea"
              style={{
                boxSizing: "border-box",
                width: "100%",
                height: "100%",
                transform: "rotate(0deg)",
              }}
            >
              <div id="E4qzC4iiMrYSasf1" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                <div
                  id="Ys3i4bZte2cjAGR8"
                  style={{
                    backgroundColor: "#c5c7cd",
                    opacity: 1.0,
                    transform: "scale(1, 1)",
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                    position: "relative",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          id="Sa7Jx5tZzWs5J5nY"
          style={{
            display: "grid",
            position: "relative",
            gridArea: "1 / 2 / 2 / 3",
          }}
        >
          <div id="u8PbdJDPhGxEhVYX">
            <div
              id="Z1C31VCWpC0VLV2V"
              style={{
                display: "grid",
                position: "relative",
                gridArea: "3 / 2 / 9 / 3",
              }}
            >
              <div id="l77XCv5ipqp7OSGG" style={{ zIndex: 1 }}>
                <div id="gzIbuOSi1bvxdCfJ" style={{ paddingTop: "172.79054783%" }}>
                  <div
                    id="clmsoARtD4wLP5LV"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <div className="animation_container" style={{ width: "100%", height: "100%" }}>
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          transform: "rotate(0deg)",
                        }}
                      >
                        <div
                          className="animated"
                          style={{
                            width: "100%",
                            height: "100%",
                            animation:
                              "wipe-LEFT-ac1d594d-c2df-4894-aa94-08eaf7dedde4 750ms 100ms both paused",
                          }}
                        >
                          <svg
                            id="HoMZ26cYA3z7mivP"
                            viewBox="0 0 955.9148 1651.7304"
                            style={{
                              width: "100%",
                              height: "100%",
                              opacity: 1.0,
                              overflow: "hidden",
                              position: "absolute",
                              top: "0%",
                              left: "0%",
                              background: 'url("data:image/png',
                            }}
                          >
                            <g id="DueMX2pgpqTSDZOk" style={{ transform: "scale(1, 1)" }}>
                              <path
                                id="AIYJuEypAw23NIKW"
                                d="M0,0 L0,1651.73036034 L955.91476435,1651.73036034 L955.91476435,0 L0,0 Z M951.1147613,1646.93035729 L4.69999981,1646.93035729 L4.69999981,4.69999981 L951.1147613,4.69999981 L951.1147613,1646.93035729 Z"
                                style={{ fill: "#bd1723", opacity: 1.0 }}
                              />
                            </g>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="Pwn7cfpx3FxXo8Mo" style={{ zIndex: 6 }}>
                <div
                  id="mAkg4ABrRdFOVI2J"
                  style={{ boxSizing: "border-box", width: "100%", height: "100%" }}
                >
                  <div className="animation_container" style={{ width: "100%", height: "100%" }}>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        transform: "rotate(0deg)",
                      }}
                    >
                      <div
                        className="animated"
                        style={{
                          width: "100%",
                          height: "100%",
                          animation:
                            "wipe-LEFT-7ae6bf93-afe1-488d-90f4-8613cf629692 750ms 100ms both paused",
                        }}
                      >
                        <div
                          id="c7XZiZxPxw03OdfC"
                          style={{
                            opacity: 1.0,
                            display: "flex",
                            boxSizing: "border-box",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <p
                            id="tldXqR85ukzB2Xpc"
                            style={{
                              color: "#000000",
                              lineHeight: "1.4858473em",
                              textAlign: "center",
                            }}
                          >
                            <span
                              id="xwFKFi1eY3TlR0Vt"
                              style={{ color: "#000000", fontWeight: 700 }}
                            >
                              ICWP
                            </span>
                            <span id="ffCrTf8Bn2NV6Hzo" style={{ color: "#000000" }}>
                              -
                            </span>
                            <br />
                          </p>
                          <p
                            id="v8dAWh4by7M5pxbI"
                            style={{
                              color: "#000000",
                              direction: "ltr",
                              lineHeight: "1.4858473em",
                              textAlign: "center",
                              textTransform: "none",
                              letterSpacing: "0em",
                            }}
                          >
                            <span id="UNs0CiaIjGksiV0b" style={{ color: "#000000" }}>
                              {" "}
                              Comprehensive care for individuals with traumatic brain injury,
                              including medical management, rehabilitation and support services.
                            </span>
                            <br />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="QhxDFEMAfBE4jrLj">
            <div
              id="a2VFO8DotspSemB7"
              style={{
                display: "grid",
                position: "relative",
                gridArea: "4 / 4 / 10 / 5",
              }}
            >
              <div id="OjAxnq7DlESlsM2B" style={{ zIndex: 3 }}>
                <div id="ntXl8x080JETfCeV" style={{ paddingTop: "177.0741172%" }}>
                  <div
                    id="bZMQlFcdDl9bm2ZS"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <div className="animation_container" style={{ width: "100%", height: "100%" }}>
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          transform: "rotate(0deg)",
                        }}
                      >
                        <div
                          className="animated"
                          style={{
                            width: "100%",
                            height: "100%",
                            animation:
                              "wipe-LEFT-9d55a982-9a4c-4b0e-aee6-4563daebf8f5 750ms 100ms both paused",
                          }}
                        >
                          <svg
                            id="bYWtsfsjU2ETJsfc"
                            viewBox="0 0 932.7904 1651.7304"
                            style={{
                              width: "100%",
                              height: "100%",
                              opacity: 1.0,
                              overflow: "hidden",
                              position: "absolute",
                              top: "0%",
                              left: "0%",
                              background: 'url("data:image/png',
                            }}
                          >
                            <g id="QrtlkEP7kB4hMmhD" style={{ transform: "scale(1, 1)" }}>
                              <path
                                id="IA2jRTV2tNGzUOFh"
                                d="M0,0 L0,1651.73036034 L932.7903954,1651.73036034 L932.7903954,0 L0,0 Z M927.99039235,1646.93035729 L4.69999981,1646.93035729 L4.69999981,4.69999981 L927.99039235,4.69999981 L927.99039235,1646.93035729 Z"
                                style={{ fill: "#bd1723", opacity: 1.0 }}
                              />
                            </g>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="oVRfPyZBKj86KKEy" style={{ zIndex: 7 }}>
                <div
                  id="pwOsh6myIKro0Zxz"
                  style={{ boxSizing: "border-box", width: "100%", height: "100%" }}
                >
                  <div className="animation_container" style={{ width: "100%", height: "100%" }}>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        transform: "rotate(0deg)",
                      }}
                    >
                      <div
                        className="animated"
                        style={{
                          width: "100%",
                          height: "100%",
                          animation:
                            "wipe-LEFT-2814b2fa-0d35-463b-941b-62d5426e004f 750ms 100ms both paused",
                        }}
                      >
                        <div
                          id="KI6UGKNb7ekfusHH"
                          style={{
                            opacity: 1.0,
                            display: "flex",
                            boxSizing: "border-box",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <p
                            id="MaNyMNNQPmAYqeNO"
                            style={{
                              color: "#000000",
                              lineHeight: "1.48253709em",
                              textAlign: "center",
                            }}
                          >
                            <span
                              id="YAgi34FTvBce8KZI"
                              style={{ color: "#000000", fontWeight: 700 }}
                            >
                              GAPP-{" "}
                            </span>
                            <br />
                          </p>
                          <p
                            id="ksBwM714QSw4PcGn"
                            style={{
                              color: "#000000",
                              direction: "ltr",
                              lineHeight: "1.48253709em",
                              textAlign: "center",
                              textTransform: "none",
                              letterSpacing: "0em",
                            }}
                          >
                            <span id="yblZjk7nWcZCItvi" style={{ color: "#000000" }}>
                              Our team of skilled nurses and caregivers
                            </span>
                            <span
                              id="BJaRrKN4mcz7s7MQ"
                              style={{ color: "#000000", whiteSpace: "pre-wrap" }}
                            ></span>
                            <span id="hlu0QOQJlUByAbIc" style={{ color: "#000000" }}>
                              are experienced in providing care for children with complex medical
                              needs.
                            </span>
                            <br />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="oK5ft2mais3eEpdb">
            <div
              id="v9Bn2VIBikYtngpe"
              style={{
                display: "grid",
                position: "relative",
                gridArea: "2 / 11 / 5 / 12",
              }}
            >
              <div id="i5SQlFa08mWt8OU7" style={{ zIndex: 5 }}>
                <div id="h37JnQKP50fnZpI6" style={{ paddingTop: "127.18600954%" }}>
                  <div
                    id="jYZ5dDsHCOV25F5j"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <div className="animation_container" style={{ width: "100%", height: "100%" }}>
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          transform: "rotate(0deg)",
                        }}
                      >
                        <div
                          className="animated"
                          style={{
                            width: "100%",
                            height: "100%",
                            animation:
                              "wipe-RIGHT-e54408c3-5768-4bcd-91a1-0d236c41a4e4 750ms 100ms both paused",
                          }}
                        >
                          <div
                            id="ggcLIME6Ha8RKBJk"
                            style={{ width: "100%", height: "100%", opacity: 1.0 }}
                          >
                            <div
                              id="HWZU0YdtZOiDCFYv"
                              style={{
                                transform: "scale(1, 1)",
                                width: "100%",
                                height: "100%",
                                overflow: "hidden",
                                position: "relative",
                              }}
                            >
                              <div
                                id="UW3uSeNsDcPvlyXE"
                                style={{
                                  width: "calc(100% * max(1, var(--scale-fill, 1)))",
                                  height: "calc(100% / min(1, var(--scale-fill, 1)))",
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  opacity: 1.0,
                                  animation: "pulse 1.5s ease-in-out infinite",
                                }}
                              >
                                <img
                                  src={AwardImage}
                                  alt="premium medal badge label luxury red gold"
                                  loading="lazy"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    display: "block",
                                    objectFit: "cover",
                                    objectPosition: "50% 50%",
                                    transform: "translate(-50%, -50%) rotate(0deg)",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="CavGOY9hjCOkcDp6" style={{ zIndex: 9 }}>
                <div
                  id="HDEyyYz0SekFT46Y"
                  style={{ boxSizing: "border-box", width: "100%", height: "100%" }}
                >
                  <div className="animation_container" style={{ width: "100%", height: "100%" }}>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        transform: "rotate(0deg)",
                      }}
                    >
                      <div
                        className="animated"
                        style={{
                          width: "100%",
                          height: "100%",
                          animation:
                            "wipe-RIGHT-9282025a-7cf0-4682-871a-4f92cd11f0ca 750ms 100ms both paused",
                        }}
                      >
                        <div
                          id="YCRd8V2uExBvjkWO"
                          style={{
                            opacity: 1.0,
                            display: "flex",
                            boxSizing: "border-box",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <p
                            id="nHe50ftLL79P3xgb"
                            style={{
                              color: "#000000",
                              lineHeight: "1.39772979em",
                              textAlign: "center",
                            }}
                          >
                            <span id="HLUjliSPx041s1o6" style={{ color: "#000000" }}>
                              30YRS
                            </span>
                          </p>
                          <p
                            id="BAHZRzIaByTsgLKw"
                            style={{
                              color: "#000000",
                              lineHeight: "1.31244668em",
                              textAlign: "center",
                            }}
                          >
                            <span
                              id="ebJexCRhZVycqlhp"
                              style={{ color: "#000000", fontWeight: 700 }}
                            >
                              Industry Experiense
                            </span>
                            <br />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="kI5ApGItmf85yhck" style={{ zIndex: 2 }}>
            <div id="wzdc8uPjiX87dhOn" style={{ paddingTop: "173.08959707%" }}>
              <div
                id="hM2c59iOFX6nc3rK"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              >
                <div className="animation_container" style={{ width: "100%", height: "100%" }}>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      transform: "rotate(0deg)",
                    }}
                  >
                    <div
                      className="animated"
                      style={{
                        width: "100%",
                        height: "100%",
                        animation:
                          "wipe-RIGHT-aafd878b-17f6-4901-8360-2f797c8b3f67 750ms 100ms both paused",
                      }}
                    >
                      <svg
                        id="QRZmrDuTq6HFSfZV"
                        viewBox="0 0 954.2632 1651.7304"
                        style={{
                          width: "100%",
                          height: "100%",
                          opacity: 1.0,
                          overflow: "hidden",
                          position: "absolute",
                          top: "0%",
                          left: "0%",
                          background: 'url("data:image/png',
                        }}
                      >
                        <g id="jTMXKD2mK2JPEy4t" style={{ transform: "scale(1, 1)" }}>
                          <path
                            id="M1dlFMcwtcTsEorF"
                            d="M0,0 L0,1651.73036034 L954.26321746,1651.73036034 L954.26321746,0 L0,0 Z M949.46321441,1646.93035729 L4.69999981,1646.93035729 L4.69999981,4.69999981 L949.46321441,4.69999981 L949.46321441,1646.93035729 Z"
                            style={{ fill: "#bd1723", opacity: 1.0 }}
                          />
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="UXbjzZC3YybOVETT" style={{ zIndex: 8 }}>
            <div
              id="mPGRDzyWfG82Z5P8"
              style={{ boxSizing: "border-box", width: "100%", height: "100%" }}
            >
              <div className="animation_container" style={{ width: "100%", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    transform: "rotate(0deg)",
                  }}
                >
                  <div
                    className="animated"
                    style={{
                      width: "100%",
                      height: "100%",
                      animation:
                        "wipe-RIGHT-270e8eec-e906-4bc7-b48f-1595b32bb1d4 750ms 100ms both paused",
                    }}
                  >
                    <div
                      id="r3V5CUIdBRTJnFOm"
                      style={{
                        opacity: 1.0,
                        display: "flex",
                        boxSizing: "border-box",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <p
                        id="cnFEflgiysujhtx6"
                        style={{
                          color: "#000000",
                          lineHeight: "1.4762516em",
                          textAlign: "center",
                        }}
                      >
                        <span id="g8JQJB5jUvWdbMlb" style={{ color: "#000000", fontWeight: 700 }}>
                          DBHDD-{" "}
                        </span>
                        <br />
                      </p>
                      <p
                        id="RiegmrffEAK6Ib0v"
                        style={{
                          color: "#000000",
                          lineHeight: "1.47238267em",
                          textAlign: "center",
                        }}
                      >
                        <span id="DVeNiJriKRM8Ums2" style={{ color: "#000000" }}>
                          We offer a range of services to support individuals with intellectual and
                          developmental disabilities, including behavioral health services and
                          community integration programs.
                        </span>
                        <br />
                      </p>
                      <p
                        id="J4wEpTeqRP5K1HcN"
                        style={{
                          color: "#000000",
                          direction: "ltr",
                          lineHeight: "1.47238267em",
                          textAlign: "center",
                          textTransform: "none",
                          letterSpacing: "0em",
                        }}
                      >
                        <span id="CpzHMxESJKXpsKGQ" style={{ color: "#000000" }}>
                          {" "}
                        </span>
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="jPrUqChMZEzXArN7">
            <div
              id="wKILIpJB3Xh5vn2P"
              style={{
                display: "grid",
                position: "relative",
                gridArea: "7 / 10 / 12 / 13",
              }}
            >
              <div id="Nd6DmbZ6aBQIRg0a" style={{ zIndex: 4 }}>
                <div id="ZFlQMI3ST2UkkLfZ" style={{ paddingTop: "180.99583325%" }}>
                  <div
                    id="DEJkW0Uvd5Elap4L"
                    style={{
                      position: "absolute",
                      top: "16px",
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <div className="animation_container" style={{ width: "100%", height: "100%" }}>
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          transform: "rotate(0deg)",
                        }}
                      >
                        <div
                          className="animated"
                          style={{
                            width: "100%",
                            height: "100%",
                            animation:
                              "wipe-RIGHT-8b84232a-896a-4257-a2f2-6bf525997384 750ms 100ms both paused",
                          }}
                        >
                          <svg
                            id="QY5jcNLbaVG5MfFS"
                            viewBox="0 0 912.5792 1651.7304"
                            style={{
                              width: "100%",
                              height: "100%",
                              opacity: 1.0,
                              overflow: "hidden",
                              position: "absolute",
                              top: "0%",
                              left: "0%",
                              background: 'url("data:image/png',
                            }}
                          >
                            <g id="F9nMcM6YDJueU1jD" style={{ transform: "scale(1, 1)" }}>
                              <path
                                id="e5RoIMWfBkbab0bz"
                                d="M0,0 L0,1651.73036034 L912.57921698,1651.73036034 L912.57921698,0 L0,0 Z M907.77921393,1646.93035729 L4.69999981,1646.93035729 L4.69999981,4.69999981 L907.77921393,4.69999981 L907.77921393,1646.93035729 Z"
                                style={{ fill: "#bd1723", opacity: 1.0 }}
                              />
                            </g>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="Wpq2384czQxH5ar0" style={{ zIndex: 10 }}>
                <div
                  id="YlGLAzV42ZwrciJk"
                  style={{ boxSizing: "border-box", width: "100%", height: "100%" }}
                >
                  <div className="animation_container" style={{ width: "100%", height: "100%" }}>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        transform: "rotate(0deg)",
                      }}
                    >
                      <div
                        className="animated"
                        style={{
                          width: "100%",
                          height: "100%",
                          animation:
                            "wipe-RIGHT-f03bf044-10f7-4f13-9ff2-337b7bd7f942 750ms 100ms both paused",
                        }}
                      >
                        <div
                          id="YsqZH9U9kIXmnuMA"
                          style={{
                            opacity: 1.0,
                            display: "flex",
                            boxSizing: "border-box",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <p
                            id="rgkJVqu4suGcTb7S"
                            style={{
                              color: "#000000",
                              lineHeight: "1.47625203em",
                              textAlign: "center",
                            }}
                          >
                            <span
                              id="RB7cYG04klhN9DXt"
                              style={{ color: "#000000", fontWeight: 700 }}
                            >
                              EDWP-{" "}
                            </span>
                            <br />
                          </p>
                          <p
                            id="evcD5UXFTfvykY49"
                            style={{
                              color: "#000000",
                              direction: "ltr",
                              lineHeight: "1.4843808em",
                              textAlign: "center",
                              textTransform: "none",
                              letterSpacing: "0em",
                            }}
                          >
                            <span
                              id="GUMY4VzLuNtECNnX"
                              style={{ color: "#000000", fontWeight: 700 }}
                            >
                              {" "}
                            </span>
                            <span id="K2Qdf8Ljq6a67lrB" style={{ color: "#000000" }}>
                              Our experienced, caregivers provide compassionate care to elderly
                              patients, helping them to maintain their independence and quality of
                              life.{" "}
                            </span>
                            <br />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <a id="projects" aria-hidden="true" style={{ visibility: "hidden" }} />
      <section
        id="T2NMkGKZOUkwAcmX"
        style={{
          position: "relative",
          overflow: "hidden",
          display: "grid",
          alignItems: "center",
          gridTemplateColumns: "auto 100rem auto",
          zIndex: 0,
          marginTop: "-1px",
        }}
      >
        <div
          id="T3PpJIYdrbgl5nVF"
          style={{
            gridArea: "1 / 1 / 2 / 4",
            display: "grid",
            position: "absolute",
            minHeight: "100%",
            minWidth: "100%",
          }}
        >
          <div id="SggXgrATxLg27eBg" style={{ zIndex: 0 }}>
            <div
              id="CqPQj6feXx9LAuQx"
              style={{
                boxSizing: "border-box",
                width: "100%",
                height: "100%",
                transform: "rotate(0deg)",
              }}
            >
              <div id="c1209InC4CHvAlbN" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                <div
                  id="dbNsRpnmbPf8jQfq"
                  style={{
                    backgroundColor: "#000000",
                    opacity: 1.0,
                    transform: "scale(1, 1)",
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                    position: "relative",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          id="wqYjr9PTCOkGmL0C"
          style={{
            display: "grid",
            position: "relative",
            gridArea: "1 / 2 / 2 / 3",
          }}
        >
          <div id="Cmsk5UCmTRIxuVBf" style={{ zIndex: 4 }}>
            <div
              id="HgsUHQEfXLuynwcG"
              style={{ paddingTop: "63.44560114%", transform: "rotate(0deg)" }}
            >
              <div
                id="i7X1uHSI84UVJwRI"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              >
                <div id="cZWKvNgPFo0RdGq1" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                  <div
                    id="kCHiTtk34jFkkSKB"
                    style={{
                      transform: "scale(1, 1)",
                      width: "100%",
                      height: "100%",
                      overflow: "hidden",
                      position: "relative",
                    }}
                  >
                    <div
                      id="f2NBFqhRyK3SOCgG"
                      style={{
                        width: "calc(100% * max(1, var(--scale-fill, 1)))",
                        height: "calc(105.20823949% / min(1, var(--scale-fill, 1)))",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        opacity: 1,
                        animation: "auto ease 0s 1 normal none running none",
                      }}
                    >
                      <img
                        src={RestingPatiantImage}
                        alt="Nurse Taking Care of an Elderly Woman "
                        loading="lazy"
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "block",
                          objectFit: "cover",
                          objectPosition: "50% 50%",
                          transform: "translate(-50%, -50%) rotate(0deg)",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="EONmJlhFuBfu0xiM">
            <div
              id="hOE8WAqz5b9ndbqd"
              style={{
                display: "grid",
                position: "relative",
                gridArea: "4 / 2 / 6 / 6",
              }}
            >
              <div id="X8l0aTR5IlPmwv6M" style={{ zIndex: 2 }}>
                <div
                  id="TIHGDm44Cniw1vg9"
                  style={{ paddingTop: "76.52212855%", transform: "rotate(0deg)" }}
                >
                  <div
                    id="GrA9bG7H8acDZDCo"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <div
                      id="kopYblTlrMwwEqvd"
                      style={{ width: "100%", height: "100%", opacity: 1.0 }}
                    >
                      <div
                        id="QUYweIuk6pAJd3W1"
                        style={{
                          transform: "scale(1, 1)",
                          width: "100%",
                          height: "100%",
                          overflow: "hidden",
                          position: "relative",
                        }}
                      >
                        <div
                          id="bHxCip4GgBDnyoZB"
                          style={{
                            width: "calc(114.63989296% * max(1, var(--scale-fill, 1)))",
                            height: "calc(100% / min(1, var(--scale-fill, 1)))",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            opacity: 1,
                            animation: "auto ease 0s 1 normal none running none",
                          }}
                        >
                          <img
                            src={WheelChairImage}
                            alt="Nurse Taking Care of an Elderly Woman "
                            loading="lazy"
                            srcSet={`${WheelChairImage}  1204w, ${WheelChairImage} 1805w`}
                            sizes="(max-width: 375px) 50.89540648vw, (min-width: 375.05px) and (max-width: 480px) 50.89540648vw, (min-width: 480.05px) and (max-width: 768px) 50.89540648vw, (min-width: 768.05px) and (max-width: 1024px) 50.89540648vw, (min-width: 1024.05px) 50.89540648vw"
                            style={{
                              width: "100%",
                              height: "100%",
                              display: "block",
                              objectFit: "cover",
                              objectPosition: "50% 50%",
                              transform: "translate(-50%, -50%) rotate(0deg)",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="KtETJnG6VhxbQeN3">
            <div
              id="Rf1saeE2U1lQU1vt"
              style={{
                display: "grid",
                position: "relative",
                gridArea: "7 / 3 / 8 / 5",
              }}
            >
              <div id="G5XcDjTvJfhcL1KU" style={{ zIndex: 3 }}>
                <div
                  id="WjNBPjU0n2lGC0EH"
                  style={{ paddingTop: "28.125%", transform: "rotate(0deg)" }}
                >
                  <div
                    id="qVHeGMuw1fV0HF9O"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <a
                      id="kg8tP7XNN4bINXqQ"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://forms.gle/NkWLCqjFwbtjLTdm9"
                      style={{ pointerEvents: "all" }}
                      data-interstitial-link=""
                    >
                      <div
                        id="oosYrIYzMmYpsFAg"
                        style={{ width: "100%", height: "100%", opacity: 1.0 }}
                      >
                        <div
                          id="q55jrg4Fq96Nldpw"
                          style={{
                            transform: "scale(1, 1)",
                            width: "100%",
                            height: "100%",
                            overflow: "hidden",
                            position: "relative",
                          }}
                        >
                          <div
                            id="Y9tWmLsXCqZB3WVI"
                            style={{
                              width: "calc(100% * max(1, var(--scale-fill, 1)))",
                              height: "calc(100% / min(1, var(--scale-fill, 1)))",
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              opacity: 1,
                              animation: "auto ease 0s 1 normal none running none",
                            }}
                          >
                            <img
                              src={ButtonImage}
                              alt="Button"
                              loading="lazy"
                              style={{
                                width: "100%",
                                height: "100%",
                                display: "block",
                                objectFit: "cover",
                                objectPosition: "50% 50%",
                                transform: "translate(-50%, -50%) rotate(0deg)",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div id="qSHJbRetCVKoRsdO" style={{ zIndex: 8, pointerEvents: "none" }}>
                <div
                  id="q8ggHWJBcXqflX2C"
                  style={{
                    boxSizing: "border-box",
                    width: "100%",
                    height: "100%",
                    transform: "rotate(0deg)",
                  }}
                >
                  <div
                    id="sJEemP9xMe2ftdkJ"
                    style={{
                      opacity: 1.0,
                      display: "flex",
                      boxSizing: "border-box",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <p
                      id="Wt9TTYELXBum9yxE"
                      style={{
                        color: "#ffffff",
                        lineHeight: "1.39915515em",
                        textAlign: "center",
                      }}
                    >
                      <span id="yh4pE38QAbVjXRep" style={{ color: "#ffffff", fontWeight: 700 }}>
                        APPLY NOW!
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="OK1vTNfjGM1ov4vV">
            <div
              id="w1wHkC1Yu5MxO1LO"
              style={{
                display: "grid",
                position: "relative",
                gridArea: "2 / 8 / 9 / 9",
              }}
            >
              <div id="ykzECYCWLJWIMnLZ" style={{ zIndex: 6 }}>
                <div
                  id="c2ZiFqm2RsHSfKuj"
                  style={{
                    boxSizing: "border-box",
                    width: "100%",
                    height: "100%",
                    transform: "rotate(0deg)",
                  }}
                >
                  <div
                    id="xNnCswL01UvjYUMd"
                    style={{
                      opacity: 1.0,
                      display: "flex",
                      boxSizing: "border-box",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <p
                      id="W12Mp8BOWiruKw9c"
                      style={{
                        color: "#ffffff",
                        direction: "ltr",
                        marginLeft: "0em",
                        lineHeight: "1em",
                        letterSpacing: "0em",
                      }}
                    >
                      <span id="KIuMkhsIOzFbfWVE" style={{ color: "#ffffff" }}>
                        INSIGHT
                      </span>
                      <span
                        id="da48LklEEPwnGiYA"
                        style={{ textDecorationLine: "none", color: "#ffffff" }}
                      >
                        {" "}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div id="OK5e9K9ewpI2JMKx" style={{ zIndex: 7 }}>
                <div
                  id="XexK9M4YS6jTwRSB"
                  style={{
                    boxSizing: "border-box",
                    width: "100%",
                    height: "100%",
                    transform: "rotate(0deg)",
                  }}
                >
                  <div
                    id="kzdHaTA9PJVl4OaB"
                    style={{
                      opacity: 1.0,
                      display: "flex",
                      boxSizing: "border-box",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <p
                      id="RsdNPazz5xf8WxLz"
                      style={{
                        color: "#ffffff",
                        lineHeight: "1.38750867em",
                      }}
                    >
                      <span id="FK4EHZKtXDZDZLs5" style={{ color: "#ffffff" }}>
                        In addition To our specialized services, we also offer private duty home
                        care services to support individuals who need assistance with activities of
                        daily living.
                      </span>
                      <span id="R28DpJ8LO3oEe9NL" style={{ color: "#ffffff" }}>
                        {" "}
                      </span>
                      <br />
                    </p>
                    <p
                      id="V8kGV1eLT5Tr5VZq"
                      style={{
                        color: "#ffffff",
                        lineHeight: "1.38750867em",
                        whiteSpace: "pre",
                      }}
                    >
                      <br />
                    </p>
                    <p
                      id="xDjh9qFYQ29yPKXW"
                      style={{
                        color: "#ffffff",
                        lineHeight: "1.38750867em",
                      }}
                    >
                      <span id="lC1JtdwlWjyzuC9s" style={{ color: "#ffffff" }}>
                        If you are a dedicated LPN or RN, who is passionate about providing
                        high-quality care to patient we want to hear from you. Join the MYKA PCH
                        team and make a difference in the lives of those we serve. Apply now to take
                        the next step in your career.
                      </span>
                      <br />
                    </p>
                    <p
                      id="I4jckjq6QSn0pIBp"
                      style={{
                        color: "#ffffff",
                        lineHeight: "1.38750867em",
                        textTransform: "none",
                        letterSpacing: "0em",
                        whiteSpace: "pre",
                      }}
                    >
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <a id="page-7" aria-hidden="true" style={{ visibility: "hidden" }} />
      <section
        id="c85MiQ8j8pyAFfoz"
        style={{
          position: "relative",
          overflow: "hidden",
          display: "grid",
          alignItems: "center",
          gridTemplateColumns: "auto 100rem auto",
          zIndex: 0,
          marginTop: "-1px",
        }}
      >
        <div
          id="NdSP5GhekaOasljF"
          style={{
            gridArea: "1 / 1 / 2 / 4",
            display: "grid",
            position: "absolute",
            minHeight: "100%",
            minWidth: "100%",
          }}
        >
          <div id="vAFmLVjpViOO8pEh" style={{ zIndex: 0 }}>
            <div
              id="IMZj5uwrJ2TTLRSJ"
              style={{
                boxSizing: "border-box",
                width: "100%",
                height: "100%",
                transform: "rotate(0deg)",
              }}
            >
              <div id="Ki3JYTh1CWCLFnYM" style={{ width: "100%", height: "100%", opacity: 1.0 }}>
                <div
                  id="Ljn2svJEAw9muVOE"
                  style={{
                    backgroundColor: "#ffffff",
                    opacity: 1.0,
                    transform: "scale(1, 1)",
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                    position: "relative",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          id="eIdYn4hrBVqteSux"
          style={{
            display: "grid",
            position: "relative",
            gridArea: "1 / 2 / 2 / 3",
          }}
        >
          <div id="WsXKJi0tUQy6M0Yj">
            <div
              id="ejASdF8seWdXb62i"
              style={{
                display: "grid",
                position: "relative",
                gridArea: "3 / 2 / 4 / 5",
              }}
            >
              <div id="eRPPNza9h5HcdgF7" style={{ zIndex: 9 }}>
                <div
                  id="nRfnhX0YzOaQ0Xdw"
                  style={{
                    boxSizing: "border-box",
                    width: "100%",
                    height: "100%",
                    transform: "rotate(0deg)",
                  }}
                >
                  <div
                    id="ULaD2Y01Sk5mMOak"
                    style={{
                      opacity: 1.0,
                      display: "flex",
                      boxSizing: "border-box",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <p
                      id="N6VpBGKRcBI8WMxl"
                      style={{
                        color: "#101922",
                        direction: "ltr",
                        marginLeft: "0em",
                        lineHeight: "1em",
                        textTransform: "none",
                        letterSpacing: "0em",
                      }}
                    >
                      <span
                        id="vyVOsEHR4wR6SVT6"
                        style={{ textDecorationLine: "none", color: "#101922" }}
                      >
                        Contact Information
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div id="owTAQ0z6seFDL7zt" style={{ zIndex: 11 }}>
                <div
                  id="koN7beDlYDRe7jfg"
                  style={{
                    boxSizing: "border-box",
                    width: "100%",
                    height: "100%",
                    transform: "rotate(0deg)",
                  }}
                >
                  <div
                    id="bylecg2blPm2OwBL"
                    style={{
                      opacity: 1.0,
                      display: "flex",
                      boxSizing: "border-box",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <p
                      id="oHXHqNq8CxsueFnV"
                      style={{
                        color: "#101922",
                        direction: "ltr",
                        marginLeft: "0em",
                        lineHeight: "1.0125em",
                        textAlign: "left",
                        textTransform: "none",
                        letterSpacing: "0em",
                      }}
                    >
                      <span
                        id="bQsxNwYwdRiPkaNm"
                        style={{
                          textDecorationLine: "none",
                          color: "#101922",
                          fontStyle: "normal",
                          fontWeight: 700,
                        }}
                      >
                        Mailing Address
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div id="MRdQu4FBvWGPGryX" style={{ zIndex: 10 }}>
                <div
                  id="ZV7uUR4i3lj84q73"
                  style={{
                    boxSizing: "border-box",
                    width: "100%",
                    height: "100%",
                    transform: "rotate(0deg)",
                  }}
                >
                  <div
                    id="vUxUbys62KfUqI6i"
                    style={{
                      opacity: 1.0,
                      display: "flex",
                      boxSizing: "border-box",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <p
                      id="n6ALYRGoNIPBU9e2"
                      style={{
                        color: "#000000",
                        lineHeight: "1.4em",
                      }}
                    >
                      <span id="xvpO3h4dCYZQHNv7" style={{ color: "#000000" }}>
                        158 Fairview Road Suite B
                      </span>
                    </p>
                    <p
                      id="E5A0QPuBdhE3VROO"
                      style={{
                        color: "#96aec4",
                        direction: "ltr",
                        marginLeft: "0em",
                        lineHeight: "1.4em",
                        textAlign: "left",
                        textTransform: "none",
                        letterSpacing: "0em",
                      }}
                    >
                      <span id="JqmIpeHkQh75MepK" style={{ color: "#000000" }}>
                        Ellenwood Ga, 30294
                      </span>
                      <br />
                    </p>
                  </div>
                </div>
              </div>
              <div id="BGFMS2RR1T9II4YV" style={{ zIndex: 13 }}>
                <div
                  id="sXAaBpS0zx4H2vOJ"
                  style={{
                    boxSizing: "border-box",
                    width: "100%",
                    height: "100%",
                    transform: "rotate(0deg)",
                  }}
                >
                  <div
                    id="FBw4YWZt1Y5OIuqw"
                    style={{
                      opacity: 1.0,
                      display: "flex",
                      boxSizing: "border-box",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <p
                      id="aTIpy788F0YNw6gv"
                      style={{
                        color: "#101922",
                        direction: "ltr",
                        marginLeft: "0em",
                        lineHeight: "1.0125em",
                        textAlign: "left",
                        textTransform: "none",
                        letterSpacing: "0em",
                      }}
                    >
                      <span
                        id="r2R54GRNa8CypebT"
                        style={{
                          textDecorationLine: "none",
                          color: "#101922",
                          fontStyle: "normal",
                          fontWeight: 700,
                        }}
                      >
                        Email Address
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div id="bxYt3OgDKC4xflmY" style={{ zIndex: 14 }}>
                <div
                  id="mO4wzJEPpkxOftS4"
                  style={{
                    boxSizing: "border-box",
                    width: "100%",
                    height: "100%",
                    transform: "rotate(0deg)",
                  }}
                >
                  <div
                    id="OMWWFAeGDtRPV7Sb"
                    style={{
                      opacity: 1.0,
                      display: "flex",
                      boxSizing: "border-box",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <p
                      id="BYPYCHDU7OlSjV2c"
                      style={{
                        color: "#000000",
                        direction: "ltr",
                        marginLeft: "0em",
                        lineHeight: "1.4em",
                        textAlign: "left",
                        textTransform: "none",
                        letterSpacing: "0em",
                      }}
                    >
                      <span id="gNTAUgIzUaD2Rs06" style={{ color: "#000000" }}>
                        MYKAPCH.hr@gmail.com
                      </span>
                      <br />
                    </p>
                  </div>
                </div>
              </div>
              <div id="hpeyZ92ab31eREpU" style={{ zIndex: 12 }}>
                <div
                  id="pD0kzGq1kLx54a2S"
                  style={{
                    boxSizing: "border-box",
                    width: "100%",
                    height: "100%",
                    transform: "rotate(0deg)",
                  }}
                >
                  <div
                    id="pFHmlClCkB6kdaez"
                    style={{
                      opacity: 1.0,
                      display: "flex",
                      boxSizing: "border-box",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <p
                      id="XkHSwDE7oqwMyWL7"
                      style={{
                        color: "#101922",
                        direction: "ltr",
                        marginLeft: "0em",
                        lineHeight: "1.0125em",
                        textAlign: "left",
                        textTransform: "none",
                        letterSpacing: "0em",
                      }}
                    >
                      <span
                        id="H1gQBxYuKZcFaDE9"
                        style={{
                          textDecorationLine: "none",
                          color: "#101922",
                          fontStyle: "normal",
                          fontWeight: 700,
                        }}
                      >
                        Phone Number
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div id="TDcUqR484nocyt77" style={{ zIndex: 15 }}>
                <div
                  id="jiNcs7LojO8ChMNY"
                  style={{
                    boxSizing: "border-box",
                    width: "100%",
                    height: "100%",
                    transform: "rotate(0deg)",
                  }}
                >
                  <div
                    id="fVzlRlIKSIjHpp2J"
                    style={{
                      opacity: 1.0,
                      display: "flex",
                      boxSizing: "border-box",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <p
                      id="iLAnHYrLq0AZ1fbe"
                      style={{
                        color: "#96aec4",
                        direction: "ltr",
                        marginLeft: "0em",
                        lineHeight: "1.4em",
                        textAlign: "left",
                        textTransform: "none",
                        letterSpacing: "0em",
                      }}
                    >
                      <span id="zdtcsMpYoJGP3Fr8" style={{ color: "#000000" }}>
                        (678) 272-7820
                      </span>
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="dNV7QOHJmBMIR8do">
            <div
              id="cptnjYzeKMbd7tD2"
              style={{
                display: "grid",
                position: "relative",
                gridArea: "5 / 3 / 8 / 4",
              }}
            >
              <div id="o103wLUjzjdJ0vbj" style={{ zIndex: 7 }}>
                <div
                  id="TulVCdmSI6zJAGx0"
                  style={{ paddingTop: "28.125%", transform: "rotate(0deg)" }}
                >
                  <div
                    id="zAVHMdYGJ3uUXEFp"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <a
                      id="rrmWy3QIGFsnA6vK"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://forms.gle/NkWLCqjFwbtjLTdm9"
                      style={{ pointerEvents: "all" }}
                      data-interstitial-link=""
                    >
                      <div
                        id="fvDkGtMueCe1vSlH"
                        style={{ width: "100%", height: "100%", opacity: 1.0 }}
                      >
                        <div
                          id="sYyCzVFTr1AGImgh"
                          style={{
                            transform: "scale(1, 1)",
                            width: "100%",
                            height: "100%",
                            overflow: "hidden",
                            position: "relative",
                          }}
                        >
                          <div
                            id="zT8pDkDiLCuONSiq"
                            style={{
                              width: "calc(100% * max(1, var(--scale-fill, 1)))",
                              height: "calc(100% / min(1, var(--scale-fill, 1)))",
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              opacity: 1,
                              animation: "auto ease 0s 1 normal none running none",
                            }}
                          >
                            <img
                              src={ButtonImage}
                              alt="Button"
                              loading="lazy"
                              style={{
                                width: "100%",
                                height: "100%",
                                display: "block",
                                objectFit: "cover",
                                objectPosition: "50% 50%",
                                transform: "translate(-50%, -50%) rotate(0deg)",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div id="JCjXP2hfxHABIryt" style={{ zIndex: 16, pointerEvents: "none" }}>
                <div
                  id="YWW3MOpmOgfb841P"
                  style={{
                    boxSizing: "border-box",
                    width: "100%",
                    height: "100%",
                    transform: "rotate(0deg)",
                  }}
                >
                  <div
                    id="gWwroNFESTLBcPfT"
                    style={{
                      opacity: 1.0,
                      display: "flex",
                      boxSizing: "border-box",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <p
                      id="bLn45zJKclaSXxXB"
                      style={{
                        color: "#ffffff",
                        lineHeight: "1.39772886em",
                        textAlign: "center",
                      }}
                    >
                      <span id="hUhFkdkEeUA6d9sN" style={{ color: "#ffffff", fontWeight: 700 }}>
                        APPLY NOW!
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="AW5WKBsna8IRStre">
            <div
              id="J3wv3cJg8jTi4Gn6"
              style={{
                display: "grid",
                position: "relative",
                gridArea: "2 / 6 / 6 / 9",
              }}
            >
              <div id="ODW2Zr4KWDqq5gBJ" style={{ zIndex: 1 }}>
                <div
                  id="T4O3bDc2GZAAUrPZ"
                  style={{ paddingTop: "56.30239171%", transform: "rotate(0deg)" }}
                >
                  <div
                    id="pF3gaa8x2TtZRE4r"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <div
                      id="cAO7YdwobFiPYkei"
                      style={{ width: "100%", height: "100%", opacity: 1.0 }}
                    >
                      <div
                        id="CGp7jvqkH0b05nnY"
                        style={{
                          transform: "scale(1, 1)",
                          width: "100%",
                          height: "100%",
                          overflow: "hidden",
                          position: "relative",
                        }}
                      >
                        <div
                          id="rYFcXGfJ2WYKFiG9"
                          style={{
                            width: "calc(100% * max(1, var(--scale-fill, 1)))",
                            height: "calc(111.00771761% / min(1, var(--scale-fill, 1)))",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            opacity: 1.0,
                            animation: "pulse 1.5s ease-in-out infinite",
                          }}
                        >
                          <img
                            src={LogoImage}
                            alt="Contact Us Symbols On Toy Blocks - Contact Us Concept"
                            loading="lazy"
                            srcSet={`${SocialsImage} 1600w, ${SocialsImage} 2400w`}
                            sizes="(max-width: 375px) 61.23212881vw, (min-width: 375.05px) and (max-width: 480px) 61.23212881vw, (min-width: 480.05px) and (max-width: 768px) 61.23212881vw, (min-width: 768.05px) and (max-width: 1024px) 61.23212881vw, (min-width: 1024.05px) 61.23212881vw"
                            style={{
                              width: "100%",
                              height: "100%",
                              display: "block",
                              objectFit: "cover",
                              objectPosition: "50% 54.95808663%",
                              transform: "translate(-50%, -54.95808663%) rotate(0deg)",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="K0gdNuRVqJ4C0sMP" style={{ zIndex: 2 }}>
                <div
                  id="oXGBFDP4O5hLVCkW"
                  style={{ paddingTop: "126.9954095%", transform: "rotate(0deg)" }}
                >
                  <div
                    id="WOcrSJHxwLzN6dTh"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <div
                      id="FC1ukZykLxQRjIcZ"
                      style={{ width: "100%", height: "100%", opacity: 1.0 }}
                    >
                      <div
                        id="ucDor5cjqjmp2QNK"
                        style={{
                          transform: "scale(1, 1)",
                          width: "100%",
                          height: "100%",
                          overflow: "hidden",
                          position: "relative",
                        }}
                      >
                        <div
                          id="K55bOqNOaxupjWHg"
                          style={{
                            width: "calc(100% * max(1, var(--scale-fill, 1)))",
                            height: "calc(106.28976877% / min(1, var(--scale-fill, 1)))",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            opacity: 1.0,
                            animation: "pulse 1.5s ease-in-out infinite",
                          }}
                        >
                          <img
                            src={LogoImage}
                            loading="lazy"
                            srcSet={`${SocialsImage} 545w, ${LogoImage}`}
                            sizes="(max-width: 375px) 23.99889497vw, (min-width: 375.05px) and (max-width: 480px) 23.99889497vw, (min-width: 480.05px) and (max-width: 768px) 23.99889497vw, (min-width: 768.05px) and (max-width: 1024px) 23.99889497vw, (min-width: 1024.05px) 23.99889497vw"
                            style={{
                              width: "100%",
                              height: "100%",
                              display: "block",
                              objectFit: "cover",
                              objectPosition: "50% 47.04121627%",
                              transform: "translate(-50%, -47.04121627%) rotate(0deg)",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="ysr65jjvWIT1gbxc">
            <div
              id="zbHp22QpXxxTF8oc"
              style={{
                display: "grid",
                position: "relative",
                gridArea: "7 / 7 / 9 / 8",
              }}
            >
              <div id="ExVlBTD6zGtQxqgL" style={{ zIndex: 3, pointerEvents: "none" }}>
                <div
                  id="lB89nfCq4b3h4YOI"
                  style={{ paddingTop: "14.35569323%", transform: "rotate(0deg)" }}
                >
                  <div
                    id="oTq6kJkjfEZykdR5"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <svg
                      id="BBQrsInlhFI1sbuy"
                      viewBox="0 0 2408.4642 345.7517"
                      style={{
                        width: "100%",
                        height: "100%",
                        opacity: 1.0,
                        overflow: "hidden",
                        position: "absolute",
                        top: "0%",
                        left: "0%",
                        background: 'url("data:image/png',
                      }}
                    >
                      <g id="VWWMVMJKuYpFiHlM" style={{ transform: "scale(1, 1)" }}>
                        <path
                          id="loG3xt3izAOAg5VG"
                          d="M0,0 L0,345.7517331 L2408.46422449,345.7517331 L2408.46422449,0 L0,0 Z M2403.66422144,340.95173005 L4.69999981,340.95173005 L4.69999981,4.69999981 L2403.66422144,4.69999981 L2403.66422144,340.95173005 Z"
                          style={{ fill: "#bd1723", opacity: 1.0 }}
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
              <div id="bAa0QtZVlTHParfB" style={{ zIndex: 6 }}>
                <div
                  id="uWXcr0A1k6OazN9g"
                  style={{
                    boxSizing: "border-box",
                    width: "100%",
                    height: "100%",
                    transform: "rotate(0deg)",
                  }}
                >
                  <div
                    id="FIC9eZslsweKSBg0"
                    style={{
                      opacity: 1.0,
                      display: "flex",
                      boxSizing: "border-box",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <p
                      id="UMhTMNGScmWqTQH2"
                      style={{
                        color: "#000000",
                        lineHeight: "0.99999583em",
                        textTransform: "uppercase",
                        letterSpacing: "0.159em",
                      }}
                    >
                      <span id="EW7TAoR6009wfBnG" style={{ color: "#000000" }}>
                        Social | @mykapch
                      </span>
                      <br />
                    </p>
                  </div>
                </div>
              </div>
              <div
                id="DmTeAPJIN9S3eeqO"
                style={{
                  display: "grid",
                  position: "relative",
                  gridArea: "3 / 5 / 6 / 6",
                }}
              >
                <div id="MU7RIMCyCaEqHZOq" style={{ zIndex: 4, pointerEvents: "none" }}>
                  <div
                    id="gz1RkF3iTfGRtJAV"
                    style={{ paddingTop: "100%", transform: "rotate(0deg)" }}
                  >
                    <div
                      id="fSSXbD0S4GISItbn"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <svg
                        id="xCAmZ9Wr0P0YgwdG"
                        viewBox="0 0 64.0 64.0"
                        style={{
                          width: "100%",
                          height: "100%",
                          opacity: 1.0,
                          overflow: "hidden",
                          position: "absolute",
                          top: "0%",
                          left: "0%",
                          background: 'url("data:image/png',
                        }}
                      >
                        <g id="AqoU7EWqgMBuNsK6" style={{ transform: "scale(1, 1)" }}>
                          <path
                            id="dfCNDrCoiseppmXy"
                            d="M32,0 C14.32688808,0 0,14.32688808 0,32 C0,49.67311096 14.32688808,64 32,64 C49.67311096,64 64,49.67311096 64,32 C64,14.32688808 49.67311096,0 32,0 Z"
                            style={{ fill: "#343434", opacity: 1.0 }}
                          />
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
                <div id="Cwnw3RXYBtarBT3W" style={{ zIndex: 5 }}>
                  <div
                    id="UmMp3kJY91oe9LBM"
                    style={{ paddingTop: "100%", transform: "rotate(0deg)" }}
                  >
                    <div
                      id="d9cxVPeHCedVSfSa"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <a
                        id="QlL48c3iKgkBfJVE"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://instagram.com/mykapch?igshid=YmMyMTA2M2Y%3D"
                        style={{ pointerEvents: "all" }}
                        data-interstitial-link=""
                      >
                        <div
                          id="Jcpt8QdAM0zuTDD9"
                          style={{ width: "100%", height: "100%", opacity: 1.0 }}
                        >
                          <div
                            id="v9teXL0kg7wPA4Zw"
                            style={{
                              transform: "scale(1, 1)",
                              width: "100%",
                              height: "100%",
                              overflow: "hidden",
                              position: "relative",
                            }}
                          >
                            <div
                              id="rtHnluqgsK59WirI"
                              style={{
                                width: "calc(100% * max(1, var(--scale-fill, 1)))",
                                height: "calc(100% / min(1, var(--scale-fill, 1)))",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                opacity: 1.0,
                                animation: "pulse 1.5s ease-in-out infinite",
                              }}
                            >
                              <img
                                src={InstagramImage}
                                alt="Black Instagram Logo"
                                loading="lazy"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  display: "block",
                                  objectFit: "cover",
                                  objectPosition: "50% 50%",
                                  transform: "translate(-50%, -50%) rotate(0deg)",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Careers;
