import React, {
  Fragment
} from 'react';
import Maintenence from './Maintenance'

function MedicalSupplies() {
  return (
    <Fragment>
      <Maintenence/>
    </Fragment>
  );
}

export default MedicalSupplies;