import React, { Fragment, useEffect } from "react";
import Logo from "../../resources/img/logo-small.png";
import { HashLink as Link } from "react-router-hash-link";
import $ from "jquery";

function Navbar() {
  useEffect(() => {
    $(document).ready(function () {
      $(".nav-burger").click(function () {
        $(".nav__list-wrap").toggleClass("slide");
        $(".nav-burger__line").toggleClass("toggle");
        $(".nav__list").toggleClass("fadeIn");
      });
      let x = window.screen.width;
      if (x < 700) {
        $(".services").click(function () {
          $(".nav__dropdown").toggleClass("block1");
        });
        console.log("mobile");
      } else if (x > 700) {
        $(".nav__list-item--services").hover(function () {
          $(".nav__dropdown").toggleClass("block2");
        });
        console.log("desktop");
      }
    });
  }, []);
  return (
    <Fragment>
      <nav className="nav">
        <div className="nav__logo">
          <Link to="/" role="logo" className="nav__logo-anchor">
            <img src={Logo} alt="logo" className="nav__logo-img" />
          </Link>
        </div>
        <div className="nav__list-wrap">
          <ul className="nav__list">
            <li className="nav__list-item">
              <Link to="/#info" className="nav__list-anchor">
                Who are we
              </Link>
            </li>
            <li className="nav__list-item nav__list-item--services">
              <Link to="/#services" className="nav__list-anchor services">
                Services
              </Link>
              <ul className="nav__dropdown">
                <li className="nav__dropdown-item">
                  <Link to="/Careers" className="nav__dropdown-anchor">
                    Careers
                  </Link>
                </li>
                <li className="nav__dropdown-item">
                  <Link to="/Cai" className="nav__dropdown-anchor">
                    CAI
                  </Link>
                </li>
                <li className="nav__dropdown-item">
                  <Link to="/Cls" className="nav__dropdown-anchor">
                    CLS
                  </Link>
                </li>
                <li className="nav__dropdown-item">
                  <Link to="/DayProgram" className="nav__dropdown-anchor">
                    Day Program
                  </Link>
                </li>

                <li className="nav__dropdown-item">
                  <Link to="/FirstAidCPR" className="nav__dropdown-anchor">
                    First Aid & CPR
                  </Link>
                </li>
                <li className="nav__dropdown-item">
                  <Link to="/MedicalSupplies" className="nav__dropdown-anchor">
                    Medical Supplies
                  </Link>
                </li>
                <li className="nav__dropdown-item">
                  <Link to="/NursingServices" className="nav__dropdown-anchor">
                    Nursing Services
                  </Link>
                </li>
                <li className="nav__dropdown-item">
                  <Link to="/PrivetHomeCare" className="nav__dropdown-anchor">
                    Private Home Care
                  </Link>
                </li>
                <li className="nav__dropdown-item">
                  <Link to="/RespiteServices" className="nav__dropdown-anchor">
                    Respite Services
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav__list-item">
              <Link to="/#contact" className="nav__list-anchor">
                contact us
              </Link>
            </li>
          </ul>
        </div>
        <div className="nav__location">
          <Link to="/#contact" className="nav__location-anchor">
            Location
          </Link>
        </div>
        <div className="nav-burger">
          <div className="nav-burger__line nav-burger__line-1"></div>
          <div className="nav-burger__line nav-burger__line-2"></div>
          <div className="nav-burger__line nav-burger__line-3"></div>
        </div>
      </nav>
    </Fragment>
  );
}

export default Navbar;
