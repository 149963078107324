import React, {
  Fragment,
  useEffect
} from 'react';
import {
  Link
} from 'react-router-dom';
import Img2 from '../../resources/img/dominik.jpg';
import Img3 from '../../resources/img/micheile-henderson-PpZasS086os-unsplash.jpg';
import Img4 from '../../resources/img/raychan-yk7F8bdD0eU-unsplash.jpg';
import Img5 from '../../resources/img/allie-smith-HwbSRFerbvg-unsplash.jpg';
import Img6 from '../../resources/img/nathan.jpg';
import Img7 from '../../resources/img/cdc-7uSvaBY69d0-unsplash.jpg'
import Img8 from '../../resources/img/elien.jpg';
import $ from 'jquery';

function Home() {
  useEffect(() => {
    $(document).ready(function() {
      $(window).scroll(function() {
        if ($(document).scrollTop() > 20) {
          $('.heading-primary__float').addClass('color');
        } else {
          $('.heading-primary__float').removeClass('color');
        }
      });
    });
  }, []);

  return (
    <Fragment>
      <header className='header'>
        <h1 className='heading-primary heading-primary__float'>Myka PCH</h1>
      </header>
      <section className='section-info'>
        <a className='section-info__anchor' id='info'></a>
        <div className='section-info__container'>
          <h2 className='heading-secondary'>Who Are We?</h2>
          <span className='heading-secondary__span'>What We Do</span>
          <p className='heading-secondary__paragraph'>
            At MYKA PCH, Inc., our mission is to provide quality care and exceed all expectations. We are
dedicated to providing effective services to ensure residents maintain and enhance their quality
of life. We make it our primary goal to assist our residents with disabilities on functioning daily
while being independent and improving on their decision making skills. We promote the well-
being of residents by nurturing their needs, demonstrating equality care and providing a
comfortable and safe living environment.
          </p>
        </div>
      </section>
      <section className='section-services'>
        <a className='section-services__anchor' id='services'></a>
        <div className='section-services__container'>
          <div className='section-services__container-holder'>
            <img
              src={Img2}
              alt=''
              className='section-services__container-img'
            />
          </div>
          <div className='section-services__container-word'>
            <h3 className='heading-tertiary'>CAI</h3>
            <div className='heading-tertiary__paragraph'>
              CAI designed to help participants acquire, retain or improve self-help,
socialization and adaptive skills required for active participation and independent functioning
outside the home.
            </div>
            <Link to='/Cai' className='btn btn-small'>
              Learn more &rarr;
            </Link>
          </div>
        </div>
        <div className='section-services__container'>
          <div className='section-services__container-word'>
            <h3 className='heading-tertiary'>CLS</h3>
            <div className='heading-tertiary__paragraph'>
              Community Living Support services are individually tailored supports that assist with the
acquisition, retention or improvement of skills related to participants’ continued residence in
their family homes.
            </div>
            <Link to='/Cls' className='btn btn-small'>
              Learn more &rarr;
            </Link>
          </div>
          <div className='section-services__container-holder'>
            <img
              src={Img3}
              alt=''
              className='section-services__container-img'
            />
          </div>
        </div>
        <div className='section-services__container'>
          <div className='section-services__container-holder'>
            <img
              src={Img4}
              alt=''
              className='section-services__container-img'
            />
          </div>
          <div className='section-services__container-word'>
            <h3 className='heading-tertiary'>day program</h3>
            <div className='heading-tertiary__paragraph'>
              Community Access is designed to help participants acquire, retain or improve self-help, socialization and adaptive skills required for active participation and independent functioning outside the home.
            </div>
            <Link to='/DayProgram' className='btn btn-small'>
              Learn more &rarr;
            </Link>
          </div>
        </div>
        <div className='section-services__container'>
          <div className='section-services__container-word'>
            <h3 className='heading-tertiary'>First Aid & CPR</h3>
            <div className='heading-tertiary__paragraph'>
              The Heartsaver First Aid course trains participants first aid basics for the most common first aid
emergencies, including how to recognize them, how to call for help, and how to perform
lifesaving skills.
            </div>
            <Link to='/FirstAidCPR' className='btn btn-small'>
              Learn more &rarr;
            </Link>
          </div>
          <div className='section-services__container-holder'>
            <img
              src={Img3}
              alt=''
              className='section-services__container-img'
            />
          </div>
        </div>
        <div className='section-services__container'>
          <div className='section-services__container-holder'>
            <img
              src={Img5}
              alt=''
              className='section-services__container-img'
            />
          </div>
          <div className='section-services__container-word'>
            <h3 className='heading-tertiary'>SMS</h3>
            <div className='heading-tertiary__paragraph'>
              Specialized Medical Supplies consist of food supplements, special clothing, diapers, bed
wetting protective sheets and other authorized supplies specified in the Individual Service
Plan.
            </div>
            <Link to='/MedicalSupplies' className='btn btn-small'>
              Learn more &rarr;
            </Link>
          </div>
        </div>
        <div className='section-services__container'>
          <div className='section-services__container-word'>
            <h3 className='heading-tertiary'>nursing services</h3>
            <div className='heading-tertiary__paragraph'>
              Nursing Services (LPN and RN) provides one-on-one medical care to the individual in need in the comfort of the individual's home. They can offer advance care that an individual may be required. The overall goal is to provide customized care that tends to the individual.
            </div>
            <Link to='/NursingServices' className='btn btn-small'>
              Learn more &rarr;
            </Link>
          </div>
          <div className='section-services__container-holder'>
            <img
              src={Img7}
              alt=''
              className='section-services__container-img'
            />
          </div>
        </div>
        <div className='section-services__container'>
          <div className='section-services__container-holder'>
            <img
              src={Img6}
              alt=''
              className='section-services__container-img'
            />
          </div>
          <div className='section-services__container-word'>
            <h3 className='heading-tertiary'>Private Home care</h3>
            <div className='heading-tertiary__paragraph'>
              Personal care  assistants are defined as “hands-on” assistance with activities such as showering, grooming, clothing, and eating. In Georgia, providers of personal care assistant are required to have CNA (certified nursing assistant) credentials, which includes training in taking vital signs, basic first aid and CPR.
            </div>
            <Link to='/PrivetHomeCare' className='btn btn-small'>
              Learn more &rarr;
            </Link>
          </div>
        </div>
        <div className='section-services__container'>
          <div className='section-services__container-word'>
            <h3 className='heading-tertiary'>respite services</h3>
            <div className='heading-tertiary__paragraph'>
              Respite Services provide brief periods of support or relief for individuals with disabilities or
their caregivers and include maintenance respite for planned or scheduled relief or
emergency/crisis respite for a brief period of support for participants experiencing crisis
(usually behavioral) or in instances of family emergency.
            </div>
            <Link to='/RespoteServices' className='btn btn-small'>
              Learn more &rarr;
            </Link>
          </div>
          <div className='section-services__container-holder'>
            <img
              src={Img8}
              alt=''
              className='section-services__container-img'
            />
          </div>
        </div>
      </section>
      <section className='section-contact'>
        <a className='section-contact__anchor' id='contact'></a>
        <div className='section-contact__container'>
          <div className='section-contact__info'>
            <div className='section-contact__entry'>
              <p className='section-contact__top'>Address</p>
              <p className='section-contact__bottom'>
                158 Fairview Rd. Suite B, Ellenwood Ga 30294
              </p>
            </div>
            <div className='section-contact__entry'>
              <p className='section-contact__top'>Contact</p>
              <p className='section-contact__bottom'>Hana Majid</p>
            </div>
            <div className='section-contact__entry'>
              <p className='section-contact__top'>Phone</p>
              <p className='section-contact__bottom'>678 272 7820</p>
            </div>
            <div className='section-contact__entry'>
              <p className='section-contact__top'>Email</p>
              <p className='section-contact__bottom'>mykapch@gmail.com</p>
            </div>
            <div className='section-contact__entry'>
              <p className='section-contact__top'>Fax</p>
              <p className='section-contact__bottom'>404 829 2927</p>
            </div>
            <div className='section-contact__copy'>
              © 2019 MYKA PCH. All rights reserved.
            </div>
          </div>
          <div className='section-contact__form-holder'>
            <div className='section-contact__header section-contact__header-1'>
              <h3 className='heading-tertiary'>Contact Us</h3>
            </div>
            <div className='section-contact__header section-contact__header-2'>
              <h3 className='heading-tertiary'>Contact Us</h3>
            </div>
            <form
              action='https://formspree.io/mykapch@gmail.com'
              method='POST'
              className='form'
            >
              <div className='form__group'>
                <input
                  type='text'
                  className='form__input'
                  placeholder='First Name'
                  id='name'
                  name='name'
                  required
                />
                <label htmlFor='name' className='form__label'>
                  First Name
                </label>
              </div>
              <div className='form__group'>
                <input
                  type='text'
                  className='form__input'
                  placeholder='Last Name'
                  id='name'
                  name='name'
                  required
                />
                <label htmlFor='name' className='form__label'>
                  Last Name
                </label>
              </div>
              <div className='form__group'>
                <input
                  type='email'
                  className='form__input'
                  placeholder='Email'
                  id='_replyto'
                  name='_replyto'
                  required
                />
                <label htmlFor='_replyto' className='form__label'>
                  Email
                </label>
              </div>
              <div className='form__group'>
                <input
                  type='tel'
                  className='form__input'
                  placeholder='Phone Number'
                  id='phoneNumber'
                  required
                  name='phoneNumber'
                  pattern='^\d{10}$'
                />
                <label htmlFor='phoneNumber' className='form__label'>
                  Phone Number
                </label>
              </div>
              <div className='form__group'>
                <textarea
                  className='form__input form__input-message'
                  name='message'
                  rows='8'
                  id='message'
                  placeholder='Message'
                  required
                ></textarea>
              </div>
              <button className='btn btn-medium' type='submit' value='Send'>
                Get in touch &rarr;
              </button>
            </form>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default Home;