import React from "react";
import Navbar from "./components/layout/Navbar";
import Cai from "./components/pages/Cai";
import Cls from "./components/pages/Cls";
import DayProgram from "./components/pages/DayProgram";
import FirstAidCPR from "./components/pages/FirstAidCPR";
import MedicalSupplies from "./components/pages/MedicalSupplies";
import NursingServices from "./components/pages/NursingServices";
import PrivetHomeCare from "./components/pages/PrivetHomeCare";
import RespiteServices from "./components/pages/RespiteServices";
import Careers from "./components/pages/Careers";
import Home from "./components/pages/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./style/App.scss";

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route exact="exact" path="/" element={<Home />} />
          <Route exact="exact" path="Careers" element={<Careers />} />
          <Route exact="exact" path="Cai" element={<Cai />} />
          <Route exact="exact" path="Cls" element={<Cls />} />
          <Route exact="exact" path="DayProgram" element={<DayProgram />} />
          <Route exact="exact" path="FirstAidCPR" element={<FirstAidCPR />} />
          <Route exact="exact" path="MedicalSupplies" element={<MedicalSupplies />} />
          <Route exact="exact" path="NursingServices" element={<NursingServices />} />
          <Route exact="exact" path="PrivetHomeCare" element={<PrivetHomeCare />} />
          <Route exact="exact" path="RespiteServices" element={<RespiteServices />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
